import React from 'react'
import {Link} from 'react-router-dom'

const NotFoundPage: React.FunctionComponent = (): JSX.Element => {
  return (
    <div>
      404 Error Page Not Found Page <br></br>
      <br></br>
      <br></br>
      <Link to="/">Return HOME</Link>
    </div>
  )
}

export default NotFoundPage
