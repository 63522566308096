import React, {useContext, useEffect, useState, useRef} from 'react'

import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import {useQuery} from '@apollo/client'
import {ALL_PRODUCTS, GET_COLLECTIONS} from 'utils/api/graphQLQueries'
import {
  Product,
  ProductEdge,
  CollectionEdge,
} from 'shopify-storefront-api-typings'

import {useTranslation} from 'react-i18next'

import {StyledCTAButton} from 'styles/componentLibraryElements'
import {config} from 'constants/sfconfig'
import {
  StyledCard,
  StyledCardBody,
  StyledCardH3,
  StyledContainer,
  StyledImage,
  StyledImageContainer,
  StyledImageContainerInner,
  StyledLink,
  StyledNav,
  StyledNavContainer,
  StyledNavItem,
  StyledRow,
  StyledCTABox,
  StyledPriceTag,
  StyledSearchInputContainer,
  StyledSearchResultNotice,
} from './ProductOverviewPage.styled'

import {
  SpinnerContainer,
  LoadingContainer,
} from '../ProductDetail/ProductDetailPage.styled'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'

import {FixedSideBar} from 'components'

interface IProps {
  onClick?: (e: Event) => void
}

const ProductOverviewPage: React.FunctionComponent<IProps> = () => {
  const {t} = useTranslation()
  const {productsStore, userStore} = useContext(StoreContext)
  const [categoryFilter, setCategoryFilter] = useState<string | number>(0)
  const {data: allProductsData, loading} = useQuery(ALL_PRODUCTS, {
    variables: {first: 200},
  })

  const {data: collectionsData} = useQuery(GET_COLLECTIONS)

  const handleFilterClick = (cat: number | string) => {
    productsStore.filterProductsToDisplay(cat)
    setCategoryFilter(cat)
  }

  const searchInput = useRef(null)

  useEffect(() => {
    if (userStore.user.firstName) {
      const nextURL = config.sfcall.CALLBACK_URL
      const nextTitle = 'Products'
      const nextState = {
        additionalInformation:
          'Stripped the URL of Redirect Params from SalesForce',
      }

      // This will replace the current entry in the browser's history, without reloading
      window.history.replaceState(nextState, nextTitle, nextURL)
    }
  }, [])

  useEffect(() => {
    if (allProductsData) {
      const fetchedProducts = allProductsData.products.edges.map(
        (product: ProductEdge) => product.node,
      )
      productsStore.setProducts(fetchedProducts)
    }
  }, [allProductsData])

  useEffect(() => {
    if (collectionsData) {
      const fetchedCollections = collectionsData?.collections.edges.map(
        (collection: CollectionEdge) => collection.node,
      )
      productsStore.setCollections(fetchedCollections)
    }
  }, [collectionsData])

  const showSearchBar = productsStore.showSearchBar

  useEffect(() => {
    if (showSearchBar) {
      searchInput.current.focus()
    }
  })

  const handleProductClick = (product: Product) => {
    productsStore.setSelectedProduct(product)
  }

  const handleSearchBarOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value
    if (searchString.length >= 3) {
      productsStore.filterProductsBySearchString(searchString)
    }

    if (searchString.length === 0) {
      productsStore.filterProductsBySearchString('')
    }
  }

  if ((!allProductsData && !collectionsData) || loading) {
    return (
      <LoadingContainer>
        <Row className="justify-content-md-center">
          <SpinnerContainer xs lg="2">
            <Spinner animation="border" variant="primary" />
          </SpinnerContainer>
        </Row>
      </LoadingContainer>
    )
  }

  return (
    <StyledContainer>
      <FixedSideBar />
      <StyledNavContainer>
        {showSearchBar ? (
          <StyledSearchInputContainer>
            <FormControl
              type="search"
              placeholder={t('productOverview.searchInputPlaceholder')}
              className="me-2"
              name="searchinput"
              onChange={handleSearchBarOnChange}
              ref={searchInput}
              aria-label="Search"
            />
            {productsStore.noProductsFoundNotice && (
              <StyledSearchResultNotice>
                {t('productOverview.searchResultsEmpty')}
              </StyledSearchResultNotice>
            )}
          </StyledSearchInputContainer>
        ) : (
          <StyledNav justify variant="tabs" defaultActiveKey="/home">
            <StyledNavItem
              current={categoryFilter === 0 ? 'true' : ''}
              onClick={() => handleFilterClick(0)}
            >
              <span>{t('productOverview.filterAll')}</span>
            </StyledNavItem>
            {productsStore.collections?.map(collection => {
              return (
                <StyledNavItem
                  key={collection.id}
                  current={categoryFilter === collection.id ? 'true' : ''}
                  onClick={() => handleFilterClick(collection.id)}
                >
                  <span>{collection.title}</span>
                </StyledNavItem>
              )
            })}
          </StyledNav>
        )}
      </StyledNavContainer>

      {productsStore.productsFilteredNotice && (
        <StyledSearchResultNotice>
          {t('productOverview.searchResultsNotice')}{' '}
        </StyledSearchResultNotice>
      )}

      <StyledRow xs={1} md={2} lg={3} xl={4} className="g-4">
        {productsStore.productsToDisplay?.map((product, idx) => {
          const productCollectionHandle =
            product.collections.edges[0].node.handle
          const isProductInCO2Collection =
            productCollectionHandle === 'co-2-flaschen-und-zubehor'

          const displayImage = product.variants.edges[0].node.image
            ? product.variants.edges[0].node.image.originalSrc
            : ''

          const truncate = (productTitle: string) => {
            return productTitle.length > 30
              ? productTitle.substring(0, 29) + '...'
              : productTitle
          }

          const price = Number(product.variants.edges[0].node.priceV2.amount)
            .toFixed(2)
            .replace('.', ',')

          return (
            <Col key={idx}>
              <StyledLink
                to={`${product.handle}`}
                onClick={() => handleProductClick(product)}
              >
                <StyledCard>
                  <StyledImageContainer>
                    <StyledImageContainerInner>
                      <StyledImage variant="top" src={displayImage} />
                    </StyledImageContainerInner>
                  </StyledImageContainer>

                  <StyledCardBody>
                    <StyledCardH3>
                      {truncate(product.title as string)}
                    </StyledCardH3>
                    <p>Artikelnummer: {product.variants.edges[0].node.sku}</p>
                    {product.options[0].name === 'VPE' ? (
                      <p>VPE-Inhalt: {product.options[0].values[0]}</p>
                    ) : (
                      ''
                    )}
                    <StyledCTABox>
                      {!isProductInCO2Collection && (
                        <StyledPriceTag>
                          {price}{' '}
                          {product.variants.edges[0].node.priceV2.currencyCode}
                        </StyledPriceTag>
                      )}
                      <StyledCTAButton>
                        {t('productOverview.moreBtn')}
                      </StyledCTAButton>
                    </StyledCTABox>
                  </StyledCardBody>
                </StyledCard>
              </StyledLink>
            </Col>
          )
        })}
      </StyledRow>
    </StyledContainer>
  )
}

export default observer(ProductOverviewPage)
