import {initReactI18next} from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationsDe from './locales/de/de.json'
import translationsEn from './locales/en/en.json'

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    resources: {
      de: {
        translation: translationsDe,
      },
      en: {
        translation: translationsEn,
      },
    },
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['u', 'a'],
    },
  })
