import {action, makeAutoObservable} from 'mobx'
import {IStores} from './rootStore'

import {config} from 'constants/sfconfig'

export default class UserStore {
  rootStore: IStores
  constructor(rootStore: IStores) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  public isLoading = false

  public user = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    street: '',
    zipCode: '',
    city: '',
    phoneNumber: '',
    customerNumber: '',
    salutation: '',
    alreadyOrderedCO2: false,
  }

  public userToken = ''

  @action
  public triggerGetUser(codeParam: string) {
    this.getUser(codeParam)
  }

  @action
  private async getUser(codeParam: string): Promise<void> {
    this.isLoading = true

    const callbackUrl = config.sfcall.CALLBACK_URL
    const clientId = config.sfcall.CLIENT_ID
    const secret = config.sfcall.SECRET

    const accesTokenUrl = `https://waterlogic.my.site.com/services/oauth2/token?code=${codeParam}&grant_type=authorization_code&client_id=${clientId}&client_secret=${secret}&redirect_uri=${callbackUrl}`
    const apexServerUrl =
      'https://waterlogic.my.site.com/services/apexrest/webshopuserinfo'

    try {
      // Get AccessToken with returned code, ClientID and secret
      fetch(accesTokenUrl, {
        method: 'POST',
      }).then(response => {
        response.json().then(res => {
          this.userToken = res.access_token

          // Finally get the user with our fetched Token
          return fetch(apexServerUrl, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.userToken}`,
            },
          }).then(result => {
            result.json().then(userResponse => {
              this.user = userResponse
              this.rootStore.checkoutStore.customerId = this.user.customerNumber
              this.rootStore.checkoutStore.combinedAddresses = {
                ...this.rootStore.checkoutStore.combinedAddresses,
                ['salutation']: this.user.salutation,
                ['firstName']: this.user.firstName,
                ['lastName']: this.user.lastName,
                ['phone']: this.user.phoneNumber,
                ['company']: this.user.companyName,
                ['address1']: this.user.street,
                ['city']: this.user.city,
                ['zip']: this.user.zipCode,
                ['email']: this.user.email,
                ['alreadyOrderedCO2']: this.user.alreadyOrderedCO2,
              }
              this.isLoading = false
            })
          })
        })
      })
    } catch (error) {
      this.isLoading = false
      console.log('## error ', error)
    }
  }
}
