import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCartPlus} from '@fortawesome/free-solid-svg-icons'

const StyledContainer = styled(Container)`
  margin-top: 100px;
`
const StyledRow = styled(Row)`
  margin-bottom: 50px;
`

const StyledHeadline = styled.h2`
  color: #064587;
  font-size: 36px;
  font-weight: 600;
  margin-top: 2rem;
`
const StyledParagraph = styled.p`
  color: #064587;
  font-size: 18px;
  font-weight: 500;
  margin-top: 1rem;
`

const StyledIcon = styled.div`
  margin-top: 4rem;
  font-size: 6rem;
  color: ${props => props.theme.secondaryHoverColor};
`

export const CartEmpty: React.FC = () => {
  const {t} = useTranslation()

  return (
    <StyledContainer>
      <StyledRow className="text-center" xs={1}>
        <StyledIcon>
          <FontAwesomeIcon icon={faCartPlus} />
        </StyledIcon>
        <StyledHeadline>{t('cart.emptyCartHeadline')}</StyledHeadline>
        <Row className="justify-content-xs-center">
          <div>
            <StyledParagraph>
              {t('cart.emptyCartText')}{' '}
              <Link to={'/products'}>{t('cart.emptyCartLinkToProduct')}</Link>
            </StyledParagraph>
          </div>
        </Row>
      </StyledRow>
    </StyledContainer>
  )
}
