import React from 'react'
import styled from 'styled-components'
import {FixedBasketButton, FixedSearchButton} from 'components'

const StyledSideBarRight = styled.div`
  display: flex;
  position: absolute;
  right: 2.125rem;
  top: 30px;
  z-index: 3;

  @media (min-width: 1200px) {
    position: fixed;
    flex-direction: column;
    flex-wrap: wrap;
    right: 0;
    row-gap: 1rem;
    top: 75px;
  }
`

export const FixedSideBar: React.FC = () => {
  return (
    <StyledSideBarRight>
      <FixedSearchButton />
      <FixedBasketButton />
    </StyledSideBarRight>
  )
}
