import React, {useContext} from 'react'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

const StyledSearchIconContainer = styled.div`
  @media (min-width: 1200px) {
    background: white;
    box-shadow: 0 0 10px rgb(0 0 0 / 16%);
    border-radius: 10px 0 0px 10px;
    padding: 8px 1.5rem 5px 5px;
  }

  div {
    color: ${props => props.theme.textColor};
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0 1rem;

    &:hover {
      color: ${props => props.theme.primaryColor};
    }
  }
`

const FixedSearchButton: React.FC = () => {
  const {productsStore} = useContext(StoreContext)

  const iconToUse = productsStore.showSearchBar ? faTimesCircle : faSearch

  const handleSearchLightClick = (): void => {
    productsStore.toggleSearchBar()
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <StyledSearchIconContainer>
      <div onClick={handleSearchLightClick}>
        <FontAwesomeIcon icon={iconToUse}></FontAwesomeIcon>
      </div>
    </StyledSearchIconContainer>
  )
}

export default observer(FixedSearchButton)
