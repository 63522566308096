import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {StyledCTAButton} from 'styles/componentLibraryElements'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import checkSVG from 'assets/svg/check.svg'

export const StyledContainer = styled(Container)`
  margin-top: 100px;
`
export const StyledRow = styled(Row)`
  margin-bottom: 50px;
`

export const StyledFormHeadline = styled.p`
  line-height: 1;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
`

export const StyledHeadline = styled.h2`
  color: ${props => props.theme.secondaryHoverColor};
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0.5rem;
`

export const CartBox = styled(Col)`
  box-shadow: 0 13px 36px rgb(0 0 0 / 8%);
  margin-bottom: 5rem;
  margin-top: 3rem;
  min-height: 11rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

export const CheckoutButton = styled(StyledCTAButton)`
  padding: 1rem;
  justify-content: center;
  max-width: 400px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
    width: auto;
  }
`

export const ButtonLoadingSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`

export const StyledBackButton = styled(Link)`
  width: auto;
  text-decoration: none;
  margin-top: 4rem;
`

export const ButtonLoadingSpinner = styled(Spinner)`
  width: 1rem !important;
  height: 1rem !important;
  margin-left: 1rem;
`

export const StyledContentWrapper = styled(Row)`
  padding: 2.5rem 1rem;
  // justify-content: space-evenly;
  @media (min-width: 1024px) {
    margin-top: 3.875rem;
  }
`
export const StyledLeftSide = styled(Col)`
  @media (min-width: 992px) {
    text-align: right;
  }

  h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
  }

  h4 {
    font-size: 1.5rem;
    color: ${props => props.theme.secondaryHoverColor};
    font-weight: 600;
  }

  p {
    font-size: 14px;
    color: ${props => props.theme.textColor};
  }
`
export const StyledRightSide = styled(Col)`
  @media (min-width: 768px) {
    padding-bottom: 3.875rem;
  }

  .form-control: disabled, .form-control[readonly] {
    background: #f3f3f3;
  }

  .form-control[readonly]:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
  }
`

export const StyledErrorSpan = styled.span`
  color: red;
  display: block;
  font-size: 0.78rem;
  font-weight: 600;
  margin-bottom: 0 0 1rem 0;
  text-align: left;
`

export const StyledSummaryCol = styled(Col)`
  border-top: 1px solid #bcbcbc;
  padding-top: 4rem;
  padding-bottom: 1rem;
`

export const StyledAGBCol = styled(Col)`
  align-self: center;
`

export const TableHeading = styled.span`
  color: #064587;
  font-size: 14px;
`
export const EditInSummaryButtonWrapper = styled(Col)`
  text-align: right;
`
export const EditInSummaryButton = styled.span`
  color: #2dafe5;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
`
export const EditInSummaryTableHead = styled(Col)`
  ol {
    list-style: none outside none;
    padding: 1rem 0 0 0;
  }

  p {
    font-size: 14px;
  }
`

export const StyledBillingWrapper = styled(Row)`
  margin-top: 3rem;
`

export const StyledBillingCheckBoxWrapper = styled(Col)`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

export const StyledOL = styled.ol`
  word-break: break-all;
`

export const StyledBillingCheckBox = styled.div<CustomCheckBoxProps>`
  position: relative;

  label {
    cursor: pointer;
    margin: 9px 0 0 10px;
  }

  input {
    // old checkmark ... wasn't validating correctly
    // border: 0;
    // clip: rect(0 0 0 0);
    // clippath: inset(50%);
    // height: 1px;
    // margin: -1px;
    // overflow: hidden;
    // padding: 0;
    // position: absolute;
    // white-space: nowrap;
    // width: 1px;

    background-image: none;
    border-color: #ced4da !important;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    cursor: pointer;
    left: 0;
    top: 0;
    background-size: 25px;
    background-position: 50%;
    width: 32px;
    height: 32px;

    &:checked {
      background-image: url(${checkSVG}) !important;
      background-color: #fff;
      ouline: none;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
`

interface CustomCheckBoxProps {
  checked?: boolean
}

export const StyledCustomCheckbox = styled.div<CustomCheckBoxProps>`
  background-image: ${props => (props.checked ? `url(${checkSVG})` : 'none')};
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  left: 0;
  top: 0;
  background-size: 25px;
  background-position: 50%;
  width: 32px;
  height: 32px;
`

export const SuccessMessageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-flow: column wrap;
  padding: 4rem;
`
export const StyledIconContainer = styled.div`
  color: #68cf68;
  font-size: 3rem;
  margin-bottom: 2rem;
`
export const StyledSuccessMessage = styled.p`
  font-weight: 500;
  text-align: center;
  font-size: 2rem;
  line-height: 1.4;
  margin: 0;
`

export const SuccessLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  margin-top: 2rem;
`

export const StyledInvoicesRow = styled(Row)`
  margin-top: 40px;
`
export const StyledInfoIconContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin-left: 15px;
  position: relative;
`
