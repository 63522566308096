import React from 'react'
import UserStore from './UserStore'
import ProductsStore from './ProductsStore'
import CheckoutStore from './CheckoutStore'
import {Client as IClient} from 'shopify-buy'
import Client from 'shopify-buy'
import {makeAutoObservable} from 'mobx'

export interface IStores {
  client?: IClient
  productsStore?: ProductsStore
  checkoutStore?: CheckoutStore
  userStore?: UserStore
}

export default class RootStore {
  constructor() {
    this.userStore = new UserStore(this)
    this.checkoutStore = new CheckoutStore(this, this.productsStore)
    this.productsStore = new ProductsStore(this, this.checkoutStore)
    makeAutoObservable(this)
  }
  public userStore: UserStore
  public productsStore: ProductsStore
  public checkoutStore: CheckoutStore

  public client: IClient = Client.buildClient({
    storefrontAccessToken: '93a39e3e071c529bc8c8dc15c9cb5a5f',
    domain: 'waterlogic-de.myshopify.com',
  })
}

export const rootStore = new RootStore()
export const StoreContext = React.createContext(rootStore)
