import React, {useContext, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'

import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import {
  LoadingContainer,
  SpinnerContainer,
} from 'pages/ProductDetail/ProductDetailPage.styled'

import {
  ProductOverviewPage,
  ProductDetailPage,
  NotFoundPage,
  Cart,
  Checkout,
} from './pages'

export const Routing: React.FC = observer((): JSX.Element => {
  const {userStore} = useContext(StoreContext)
  const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>()

  useEffect(() => {
    if (userStore.user.firstName) {
      setUserIsLoggedIn(true)
    }
  })

  if (userIsLoggedIn) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/products" replace />} />
        <Route path="/products" element={<ProductOverviewPage />}></Route>
        <Route path="/products/:handle" element={<ProductDetailPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    )
  }

  if (userStore.isLoading) {
    return (
      <LoadingContainer>
        <Row className="justify-content-md-center">
          <SpinnerContainer xs lg="2">
            Loading...{'  '}
            <Spinner animation="border" variant="primary" />
          </SpinnerContainer>
        </Row>
      </LoadingContainer>
    )
  }

  return (
    <LoadingContainer>
      <Row className="justify-content-md-center text-center">
        <div> Sie werden zum Login weitergeleitet</div>
      </Row>
    </LoadingContainer>
  )
})
