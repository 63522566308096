import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShoppingBag} from '@fortawesome/free-solid-svg-icons'

interface BasketButtonProps {
  rightSideFixed?: boolean
}

const StyledBasketContainer = styled.div<BasketButtonProps>`
  ${({rightSideFixed}) =>
    rightSideFixed &&
    `
    position: fixed;
    top: 1.5rem;
    right: 0;
    z-index: 5;
  `}

  @media (min-width: 1200px) {
    background: white;
    box-shadow: 0 0 10px rgb(0 0 0 / 16%);
    border-radius: 10px 0 0px 10px;
    padding: 8px 1.5rem 5px 5px;
  }

  a {
    color: ${props => props.theme.textColor};
    font-size: 1.5rem;
    padding: 0.5rem 1rem;

    &:hover {
      color: ${props => props.theme.primaryColor};
    }
  }
`

export const FixedBasketButton: React.FC<BasketButtonProps> = ({
  rightSideFixed,
}) => {
  return (
    <StyledBasketContainer rightSideFixed={rightSideFixed}>
      <Link to={'/cart'}>
        <FontAwesomeIcon icon={faShoppingBag}></FontAwesomeIcon>
      </Link>
    </StyledBasketContainer>
  )
}
