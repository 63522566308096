import styled, {css} from 'styled-components'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import {Link} from 'react-router-dom'

import heroSignetCut from 'assets/images/hero-signet-cut.svg'

export const StyledContent = styled.div`
  padding: 80px 0;
  @media (max-width: 767px) {
    padding: 20px 0;
  }
`

export const StyledPageWrapper = styled.div`
  background: white;
`

export const PageContainer = styled(Container)`
  @media (min-width: 1599px) {
    max-width: 1600px;
  }
`

export const LoadingContainer = styled(Container)`
  margin-top: 70px;
`

export const SpinnerContainer = styled(Col)`
  text-align: center;
`

export const StyledHeroCol = styled(Col)`
  position: relative;

  @media (max-width: 768px) {
    order: -1;
  }

  &:after {
    background-image: url(${heroSignetCut});
    background-position: 0 125%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: -12px;
    width: 100%;
    z-index: 0;

    @media (max-width: 1279px) {
      background-position: center;
      background-size: contain;
    }
  }
`

export const StyledFigure = styled.figure`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  z-index: 1;
`

export const StyledImage = styled.img`
  height: 100%;
  max-height: 700px;
  object-fit: contain;
  padding: 80px 0;
  width: 100%;

  @media (max-width: 768px) {
    max-height: 300px;
    padding: 0;
  }
`

export const StyledCounter = styled.div`
  display: inline-block;
  margin-bottom: 40px;
  padding: 0 10px;
  user-select: none;
`
const SharedSpanStyle = css`
  background-color: ${props => props.theme.lighterGray};
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  height: 34px;
  line-height: 36px;
  position: relative;
  text-align: center;
  top: 1px;
  transition: 0.3s ease-in-out 0s;
  width: 34px;

  &:hover {
    background-color: ${props => props.theme.secondaryHoverColor};
  }
`

interface DecreaseButtonProps {
  disabled: boolean
}

export const StyledIncSpan = styled.span`
  ${SharedSpanStyle}
`
export const StyledDecSpan = styled.span<DecreaseButtonProps>`
  ${SharedSpanStyle}
  ${({disabled}) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
  `}
`
export const CounterTitle = styled.span`
  font-weight: 600;
  margin-right: 15px;
`
export const StyledValue = styled.span`
  border-radius: 50%;
  border: 1px solid ${props => props.theme.textColor};
  display: inline-block;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
  margin: 0px 5px;
  text-align: center;
  width: 45px;
`

export const StyledPrice = styled.p`
  font-size: 60px;
  font-weight: 400;
  line-height: 1;
`

export const StyledH1 = styled.h1`
  font-family: TTNormsPro;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
`

export const ProductDescription = styled.p`
  margin: 40px auto;
`

export const ButtonLoadingSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`
export const ButtonLoadingSpinner = styled(Spinner)`
  width: 1rem !important;
  height: 1rem !important;
  margin-left: 1rem;
`

export const StyledBackButton = styled(Link)`
  width: auto;
  text-decoration: none;
  margin-top: 2rem;
  display: block;
`
export const StyledOptions = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 0 10px;

  p {
    display: inline-block;
    margin-right: 1rem;
    font-weight: 600;
  }

  select {
    border-color: ${props => props.theme.textColor};
    border-radius: 25px;
    color: ${props => props.theme.textColor};
    cursor: pointer;
    font-weight: 500;
    padding: 0.5rem;

    &:hover {
      border-color: ${props => props.theme.secondaryHoverColor};
      color: ${props => props.theme.secondaryHoverColor};
    }

    &:focus-visible {
      border-color: ${props => props.theme.primaryColor};
      outline: none;
    }
  }
`
