import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import {StoreContext} from 'stores/rootStore'
import {CombinedAddressesType} from 'stores/CheckoutStore'

import CheckoutStepForm from './CheckoutStepForm'
import CheckoutStepSummary from './CheckoutStepSummary'
import CheckoutStepSuccess from './CheckoutStepSuccess'

import Row from 'react-bootstrap/Row'

import {
  CartBox,
  StyledContainer,
  StyledHeadline,
  StyledRow,
} from './Checkout.styled'

const Checkout: React.FC = () => {
  const {checkoutStore} = useContext(StoreContext)
  const {t} = useTranslation()
  const [initialValues, setInititalValues] = useState<CombinedAddressesType>()

  useEffect(() => {
    const getPrefilledValues = () => {
      setInititalValues(checkoutStore.combinedAddresses)
    }
    getPrefilledValues()
  })

  const renderCheckoutStep = () => {
    switch (checkoutStore.checkoutStep) {
      case 'form':
        return <CheckoutStepForm initialFormValues={initialValues} />
      case 'summary':
        return <CheckoutStepSummary />
      case 'success':
        return <CheckoutStepSuccess />
    }
  }

  return initialValues ? (
    <StyledContainer>
      <StyledRow className="text-center" xs={1}>
        <StyledHeadline>{t('checkout.headline')}</StyledHeadline>
      </StyledRow>
      <Row>
        <CartBox>{renderCheckoutStep()}</CartBox>
      </Row>
    </StyledContainer>
  ) : (
    <div>Loading</div>
  )
}

export default observer(Checkout)
