export interface WLDETheme {
  primaryColor?: string
  secondaryColor?: string
  secondaryHoverColor?: string
  textColor?: string
  lighterGray?: string
  fontFamily?: string
}

export const defaultTheme: WLDETheme = {
  primaryColor: '#13b5ea',
  secondaryColor: '#ff7f40',
  secondaryHoverColor: '#004b8d',
  textColor: '#404145',
  lighterGray: '#a1a1a4',
  fontFamily: 'TTNormsPro, Helvetica Neue, Helvetica, sans-serif',
}

// For testing purposes only. Can and should be removed in the future
const another = {
  primaryBg: 'red',
  primaryText: 'black',
}

export const themes = {
  default: defaultTheme,
  another,
}
