import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import {StyledCTAButton} from 'styles/componentLibraryElements'

export const ProductRowWrapper = styled(Container)``
export const SummaryRowWrapper = styled(Container)``

interface ProductRowInnerProps {
  inSummary?: boolean
}

export const ProductWrowInner = styled.div<ProductRowInnerProps>`
  border-bottom: 1px solid #bcbcbc;
  padding: 3rem 0 0.5rem;
  position: relative;

  ${({inSummary}) =>
    !inSummary &&
    `
    margin: auto;
    max-width: 85%;
  `}
`
export const StyledPrice = styled.p`
  color: #064587;
  font-size: 18px;
  font-weight: 400;
  text-align: right;
`

export const SummaryRowInner = styled.div<ProductRowInnerProps>`
  border-top: 1px solid #bcbcbc;
  ${({inSummary}) =>
    !inSummary &&
    `
    margin: auto;
    max-width: 85%;
  `}
`

export const StyledSummaryColHeadline = styled(Col)`
  padding: 2.25rem 1rem;

  > span {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
`
export const StyledSummaryColRight = styled(Col)`
  margin: auto;
  padding: 2.25rem 1rem 2.25rem 0;
  text-align: right;

  span {
    color: #064587;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
  }
`
export const StyledSummaryColRightDiscount = styled(Col)`
  ${({discountadded}) =>
    discountadded &&
    `
    padding: 2.25rem 1rem 2.25rem 0;
  `}
  text-align: ${props => (props.discountadded ? 'right' : 'center')};
`

export const StyledTotalSum = styled.span`
  font-size: 28px !important;
  font-weight: 600 !important;
`
export const StyledVAT = styled.span`
  color: #989898;
  display: block;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 0;
`

export const TableHeadCol = styled(Col)`
  font-size: 18px;
  width: max-content;
  float: right;
  font-weight: 500;

  &:after {
    position: relative;
    top: -10px;
    content: '';
    border-bottom: 2px solid;
    width: 100%;
    display: inline-block;
  }
`

export const TableHeaderWrapper = styled(Row)`
  margin: auto !important;
  max-width: 85%;
  padding-top: 0.95rem;
  position: sticky;
  top: 50px;

  @media (max-width: 500px) {
    right: 20% !important;
  }
  @media (max-width: 768px) {
    right: 25% !important;
  }
`
export const StyledCartTableHeader = styled(Col)``

export const ImageCol = styled(Col)`
  text-align: center;
`

export const StyledProductImage = styled.img`
  height: 200px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  width: auto;

  @media (max-width: 768px) {
    height: 150px;
    max-width: 200px;
  }
`

export const DescriptionCol = styled(Col)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  h3 {
    color: #191919;
    font-size: 18px;
    font-weight: 700;

    @media (max-width: 768px) {
      margin-top: 1rem;
    }

    p {
      font-weight: 400;
    }
  }
`

export const CartControlElements = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 769px) {
    align-items: center;
  }
`

export const CartControlAmount = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`
export const CartControlRemoveButton = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #2dafe5;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }

  @media (min-width: 769px) {
    margin-left: 2rem;
  }
`

export const StyledCartLink = styled(Link)`
  color: #191919;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`

export const ProductRowLoadingContainer = styled.div`
  align-items: center;
  bottom: 8px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 3rem;
  z-index: 1;

  &:before {
    background: rgba(255, 255, 255, 0.7);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const DottedSpinner = styled(Spinner)`
  margin: 0 3px;
  width: 1rem;
  height: 1rem;
  color: ${props => props.theme.primaryColor} !important;
`

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  background: transparent;
  border: none;
  color: ${props => props.theme.secondaryHoverColor};
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  padding-left: 0;

  &:after {
    margin-left: 1rem;
  }

  &:focus {
    background: transparent;
    border: none;
    box-shadow: none;
    color: ${props => props.theme.secondaryHoverColor};
  }

  &:hover,
  &:active:focus {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    color: ${props => props.theme.secondaryColor};
  }

  .show > &,
  .show > &:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
    color: ${props => props.theme.secondaryHoverColor} !important;
  }
`
export const StyledDropdownMenu = styled(Dropdown.Menu)``
export const StyledDropdownItem = styled<any>(Dropdown.Item)`
  &.active,
  &:active {
    background: ${props => props.theme.primaryColor};
  }
`
export const StyledCheckBtnCol = styled(Col)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`
export const StyledCheckBtn = styled(StyledCTAButton)`
  border-radius: 3px;
  padding: 17px;
  transition: none;
  width: 100%;

  ${({loading}) =>
    loading &&
    `
    padding: 21px;

    & > div > div {
      margin-left: 0;
    }
  `}
`
