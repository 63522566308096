const prod = {
  sfcall: {
    CALLBACK_URL: 'https://wli-de.ommax.dev/products',
    CLIENT_ID:
      '3MVG9Rd3qC6oMalVtOKKLRe7kgu1OP5iPtWg.sTHzaR4YOSUSUxiGL048zZhK1mKGmrPTUbfd.xXm8kyv4jYe',
    SECRET: '00646CCDBD60086041E28B510863A6FB856DAA2C4B5B1BD6CF9D4BF9317CC17B',
  },
}

const dev = {
  sfcall: {
    CALLBACK_URL: 'http://localhost:3000/products',
    CLIENT_ID:
      '3MVG9Rd3qC6oMalVtOKKLRe7kgu1OP5iPtWg.sTHzaR4YOSUSUxiGL048zZhK1mKGmrPTUbfd.xXm8kyv4jYe',
    SECRET: '00646CCDBD60086041E28B510863A6FB856DAA2C4B5B1BD6CF9D4BF9317CC17B',
  },
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod
