import {StyleSheet, Font} from '@react-pdf/renderer'
import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf'
import RobotoBold from '../../assets/fonts/Roboto-Bold.ttf'

// Create styles for generated PDF
// here: OderConfirmation PDF after order success

Font.register({
  family: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fonts: [
    {src: RobotoRegular, fontWeight: 400},
    {src: RobotoBold, fontStyle: 'bold', fontWeight: 700},
  ],
})

export const documentStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '20px 40px 40px 80px',
    fontSize: '10px',
    fontFamily: 'Roboto',
  },
  header: {
    backgroundColor: 'white',
    flexDirection: 'row',
    position: 'relative',
    paddingBottom: '60px',
  },
  bold: {
    fontStyle: 'bold',
  },
  logoContainerLeft: {
    alignItems: 'center',
    height: '120px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  logoContainerRight: {
    alignItems: 'center',
    height: '120px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  logoLeft: {
    height: '120px',
    width: '150px',
  },
  waterlogicLogoRight: {
    width: '120px',
  },
  headerLeft: {
    // flexGrow: 1,
  },
  headerRight: {
    flexGrow: 1,
  },
  companyAddress: {
    fontSize: '6px',
    marginBottom: '10px',
    marginTop: '5px',
  },
  customerAddressLine: {
    width: '100%',
  },
  countryInCustomerAddress: {
    marginTop: '10px',
  },
  abBlock: {
    justifyContent: 'flex-end',
    paddingLeft: '40px',
    flexWrap: 'wrap',
  },
  blockHeader: {
    alignSelf: 'center',
    backgroundColor: '#efefef',
    borderBottom: '1px solid black',
    fontSize: '14px',
    fontStyle: 'bold',
    paddingTop: '5px',
    height: '25px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  abBlockTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  abBlockText: {},
  main: {
    minHeight: '200px',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '30px',
    maxWidth: '66%',
  },
  mainTitle: {
    fontStyle: 'bold',
    width: '30%',
  },
  mainTitle2: {
    fontStyle: 'bold',
    width: '70%',
  },
  deliveryAddressContainer: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: '10px',
  },
  deliveryAddressHeadline: {
    textDecoration: 'underline',
    paddingRight: '5px',
  },
  deliveryAddressText: {
    flexDirection: 'row',
    fontSize: '10px',
  },
  positionsContainer: {
    fontSize: '10px',
    borderTop: '1px solid black',
  },
  positionsTableHeader: {
    alignItems: 'center',
    backgroundColor: '#efefef',
    borderBottom: '1px solid black',
    fontStyle: 'bold',
    flexDirection: 'row',
    height: '20px',
  },
  positionsTableRows: {
    backgroundColor: 'white',
    fontSize: '9px',
  },
  pos: {
    width: '6.5%',
  },
  pos2: {
    width: '6.5%',
  },
  artnr: {
    width: '20%',
  },
  artnr2: {
    width: '20%',
  },
  artikelbezeichnung: {
    width: '37%',
  },
  artikelbezeichnung2: {
    width: '37%',
  },
  menge: {
    width: '8.5%',
    marginRight: '1%',
  },
  menge2: {
    width: '8.5%',
    marginRight: '1%',
  },
  ep: {
    width: '6.5%',
  },
  ep2: {
    width: '6.5%',
  },
  pe: {
    width: '4.5%',
  },
  pe2: {
    width: '6.5%',
  },
  mwst: {
    width: '6.5%',
  },
  mwst2: {
    width: '6.5%',
  },
  betrag: {
    width: '8.5%',
    textAlign: 'right',
  },
  betrag2: {
    width: '6.5%',
    textAlign: 'right',
  },
  summaryRow: {
    flexDirection: 'row',
  },
  summaryRow2: {
    flexDirection: 'row',
    paddingBottom: '8px',
  },
  summaryContainer: {
    borderTop: '1px solid black',
    paddingBottom: '5px',
  },
  totalLine: {
    fontStyle: 'bold',
    marginTop: '5px',
    width: '83.5%',
  },
  totalLineCurrency: {
    fontStyle: 'bold',
    marginTop: '5px',
    width: '10%',
  },
  totalLineAmount: {
    fontStyle: 'bold',
    marginTop: '5px',
    width: '6.5%',
    textAlign: 'right',
  },
  totalLine2: {
    marginTop: '5px',
    width: '83.5%',
  },
  totalLineCurrency2: {
    marginTop: '5px',
    width: '10%',
  },
  totalLineAmount2: {
    marginTop: '5px',
    width: '6.5%',
    textAlign: 'right',
  },
  positionsContent: {
    flexDirection: 'row',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  summaryContainerFinal: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    padding: '8px 0',
  },
  twentyfive: {
    width: '26.5%',
    padding: '2px 0',
  },
  seventyfive: {
    width: '73.5%',
    padding: '2px 0',
  },
  agbRow: {
    width: '82%',
    paddingTop: '8px',
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    left: '12.5%',
    right: '12.5%',
    fontSize: '6px',
    textAlign: 'center',
  },
  footerRow: {
    flexDirection: 'row',
  },
  footerColumn: {
    textAlign: 'left',
    flexGrow: 1,
  },
  lastline: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
})
