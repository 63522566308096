import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import {useForm} from 'react-hook-form'
import {useTranslation, Trans} from 'react-i18next'
import {yupResolver} from '@hookform/resolvers/yup'

import * as yup from 'yup'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
  ButtonWrapper,
  ButtonLoadingSpinnerContainer,
  ButtonLoadingSpinner,
  CheckoutButton,
  StyledContentWrapper,
  StyledLeftSide,
  StyledRightSide,
  StyledSummaryCol,
  StyledAGBCol,
  TableHeading,
  EditInSummaryButton,
  EditInSummaryButtonWrapper,
  EditInSummaryTableHead,
  StyledBillingCheckBox,
  StyledCustomCheckbox,
  StyledBillingCheckBoxWrapper,
  StyledOL,
} from './Checkout.styled'

import {CartInnerContent} from 'components'

export interface IFormDataSummary {
  acceptTerms: boolean
}

const CheckoutStepSummary: React.FC = () => {
  const {checkoutStore} = useContext(StoreContext)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const {t} = useTranslation()

  const FormSchema = yup.object().shape({
    acceptTerms: yup
      .boolean()
      .oneOf([true], t('checkout.form.errors.acceptTerms')),
  })

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormDataSummary>({
    resolver: yupResolver(FormSchema),
  })

  const handleEditInSummaryClick = () => {
    checkoutStore.setCheckoutStep('form')
  }

  const handleAcceptTerms = () => {
    setAcceptedTerms(!acceptedTerms)
  }

  const onSubmit = () => {
    checkoutStore.sendOrderToSteps()
  }

  return (
    <StyledContentWrapper>
      <StyledLeftSide md={12} lg={3}>
        <h5>{t('checkout.steps.two')}</h5>
        <h4>{t('checkout.stepTwoSubtitle')}</h4>
        <p>{t('checkout.checkyourorder')}</p>
      </StyledLeftSide>
      <StyledRightSide lg={{span: 7, offset: 1}}>
        <Row>
          <EditInSummaryTableHead md={4}>
            <TableHeading>
              {t('checkout.summary.tableHeadContact')}
            </TableHeading>
            <StyledOL>
              <li>
                {checkoutStore.customerContact.firstName}{' '}
                {checkoutStore.customerContact.lastName}
              </li>
              <li>{checkoutStore.customerContact.email}</li>
              <li>{checkoutStore.customerContact.phone}</li>
            </StyledOL>
          </EditInSummaryTableHead>
          <EditInSummaryTableHead md={4}>
            <TableHeading>
              {t('checkout.summary.tableHeadBillingAddress')}
            </TableHeading>
            <StyledOL>
              <li>{checkoutStore.combinedAddresses.company}</li>
              <li>{checkoutStore.combinedAddresses.address1}</li>
              <li>
                {checkoutStore.combinedAddresses.zip}{' '}
                {checkoutStore.combinedAddresses.city}
              </li>
            </StyledOL>
          </EditInSummaryTableHead>
          {checkoutStore.combinedAddresses.firstName2 ? (
            <EditInSummaryTableHead md={3}>
              <TableHeading>
                {t('checkout.summary.tableHeadDeliveryAddress')}
              </TableHeading>
              <StyledOL>
                <li>
                  {checkoutStore.combinedAddresses.firstName2}{' '}
                  {checkoutStore.combinedAddresses.lastName2}
                </li>
                <li>{checkoutStore.combinedAddresses.address12}</li>
                <li>
                  {checkoutStore.combinedAddresses.zip2}{' '}
                  {checkoutStore.combinedAddresses.city2}
                </li>
              </StyledOL>
            </EditInSummaryTableHead>
          ) : (
            ''
          )}
          <EditInSummaryButtonWrapper lg={1}>
            <EditInSummaryButton onClick={() => handleEditInSummaryClick()}>
              {t('checkout.summary.editButton')}
            </EditInSummaryButton>
          </EditInSummaryButtonWrapper>
        </Row>
        <Row>
          <CartInnerContent inSummary />
        </Row>
      </StyledRightSide>
      <StyledSummaryCol xs={12}>
        <Row>
          <StyledLeftSide md={12} lg={3}>
            <h4>{t('checkout.paymentType')}</h4>
          </StyledLeftSide>
          <StyledRightSide lg={{span: 8, offset: 1}} md={12}>
            <p>{t('checkout.paymentTypeDescription')}</p>
          </StyledRightSide>
        </Row>
      </StyledSummaryCol>

      <StyledSummaryCol xs={12}>
        <Row>
          <StyledLeftSide md={12} lg={3}>
            <h4>{t('checkout.orderInformation')}</h4>
          </StyledLeftSide>
          <StyledRightSide lg={{span: 8, offset: 1}} md={12}>
            <p>
              {t('checkout.form.placeholder.orderNumber')}: &nbsp;
              {checkoutStore.billingAddress.orderNumber}
            </p>
            <p>
              {t('checkout.form.placeholder.invoiceDelivery.title2')}: &nbsp;
              {checkoutStore.billingAddress.invoices === 'email'
                ? t('checkout.form.placeholder.invoiceDelivery.email')
                : t('checkout.form.placeholder.invoiceDelivery.post')}
            </p>
          </StyledRightSide>
        </Row>
      </StyledSummaryCol>

      <StyledSummaryCol xs={12}>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <StyledLeftSide md={12} lg={3}>
              <h4>{t('checkout.acceptTermsHeadline')}</h4>

              <Trans
                i18nKey="checkout.acceptTermsText"
                t={t}
                components={[
                  <a
                    key="1"
                    href="https://www.waterlogic.de/agb/#cmpnoscreen"
                    target="_blank"
                    rel="noreferrer"
                  />,
                ]}
              />
            </StyledLeftSide>

            <StyledAGBCol lg={{span: 8, offset: 1}} md={12}>
              <Row>
                <StyledBillingCheckBoxWrapper xs="12">
                  {/* <label htmlFor="acceptTerms">
                    <StyledCustomCheckbox
                      checked={acceptedTerms}
                      className="customCheckbox"
                    ></StyledCustomCheckbox>
                  </label> */}
                  <Form.Group controlId="acceptTerms">
                    <StyledBillingCheckBox>
                      <Form.Check
                        type="checkbox"
                        name="acceptTerms"
                        label={
                          <Trans
                            i18nKey="checkout.acceptedTermsConfirmation"
                            t={t}
                            components={[
                              <a
                                key="1"
                                href="https://www.waterlogic.de/agb/#cmpnoscreen"
                                target="_blank"
                                rel="noreferrer"
                              />,
                            ]}
                          />
                        }
                        onClick={handleAcceptTerms}
                        checked={acceptedTerms}
                        feedback={
                          errors.acceptTerms && errors.acceptTerms.message
                        }
                        feedbackType="invalid"
                        isInvalid={!!errors.acceptTerms}
                        {...register('acceptTerms')}
                      />
                    </StyledBillingCheckBox>
                  </Form.Group>
                </StyledBillingCheckBoxWrapper>
              </Row>
            </StyledAGBCol>
          </Row>
          <ButtonWrapper as={Col} lg={{span: 7, offset: 4}} sm={12}>
            <CheckoutButton type="submit">
              {checkoutStore.isLoading ? (
                <ButtonLoadingSpinnerContainer>
                  {t('checkout.finalSubmitBtn')}
                  <ButtonLoadingSpinner animation="border" variant="white" />
                </ButtonLoadingSpinnerContainer>
              ) : (
                t('checkout.finalSubmitBtn')
              )}
            </CheckoutButton>
          </ButtonWrapper>
        </Form>
      </StyledSummaryCol>
    </StyledContentWrapper>
  )
}

export default observer(CheckoutStepSummary)
