import React, {useContext} from 'react'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import Modal from 'react-bootstrap/modal'
import Button from 'react-bootstrap/button'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShoppingBag} from '@fortawesome/free-solid-svg-icons'

interface ModalProps {
  content: string
  title: string
  linkTo?: string
  subcontent?: string
}

const InfoModal: React.FC<ModalProps> = ({
  content,
  title,
  linkTo,
  subcontent,
}) => {
  const {productsStore} = useContext(StoreContext)

  const handleClose = () => productsStore.setProductDetailModal(false)

  return (
    <Modal
      show={productsStore.showProductDetailModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p style={{marginTop: '10px'}}>{content}</p>

        {subcontent && (
          <div style={{margin: '40px 0 15px 0'}}>{subcontent}</div>
        )}
        <Link to={linkTo}>
          <Button variant="primary">
            Zum Warenkorb
            <FontAwesomeIcon
              style={{marginLeft: '10px'}}
              icon={faShoppingBag}
            ></FontAwesomeIcon>
          </Button>
        </Link>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Schliessen
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(InfoModal)
