import {gql} from '@apollo/client'

export const ALL_PRODUCTS = gql`
  query ($first: Int!) {
    products(first: $first) {
      edges {
        node {
          id
          title
          handle
          collections(first: 5) {
            edges {
              node {
                handle
              }
            }
          }
          descriptionHtml
          options {
            id
            name
            values
          }
          variants(first: 20) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                title
                sku
                selectedOptions {
                  name
                  value
                }
                image {
                  originalSrc
                  transformedSrc
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
          images(first: 10) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                originalSrc
                transformedSrc
              }
            }
          }
        }
      }
    }
  }
`

export const SINGLE_PRODUCT = gql`
  query ($handle: String!) {
    product(handle: $handle) {
      title
      handle
      id
      collections(first: 5) {
        edges {
          node {
            handle
          }
        }
      }
      descriptionHtml
      options {
        id
        name
        values
      }
      variants(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            title
            selectedOptions {
              name
              value
            }
            image {
              originalSrc
              transformedSrc
            }
            priceV2 {
              amount
              currencyCode
            }
          }
        }
      }
      images(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            originalSrc
            transformedSrc
          }
        }
      }
    }
  }
`

export const GET_COLLECTIONS = gql`
  {
    collections(first: 20) {
      edges {
        node {
          id
          title
          products(first: 100) {
            edges {
              node {
                id
                title
                handle
                collections(first: 1) {
                  edges {
                    node {
                      handle
                    }
                  }
                }
                descriptionHtml
                options {
                  id
                  name
                  values
                }
                variants(first: 250) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  edges {
                    node {
                      id
                      title
                      sku
                      selectedOptions {
                        name
                        value
                      }
                      image {
                        originalSrc
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
