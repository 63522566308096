import {action, makeAutoObservable} from 'mobx'
import {Option, OptionValue, ImageVariant} from 'shopify-buy'
import {IStores} from './rootStore'
import CheckoutStore from './CheckoutStore'
import {Product, Collection} from 'shopify-storefront-api-typings'

export interface ExtendedLineItem {
  id: string | number
  quantity: number
  title?: string
  customAttributes?: [
    {
      key: string
      value: string
    },
  ]
  variant?: {
    sku: string
    title?: string
    product?: {
      handle?: string
    }
    price: {
      amount: string
      currencyCode: string
    }
    priceV2: {
      amount: string
      currencyCode: string
    }
    image: {
      src: string
      height: number
      width: number
      altText: string
    }
  }
}

export interface ICollection {
  description?: string
  id?: string | number | undefined
  image: {
    altText: string
    id: string
    src: string
  }
  title: string
  products: Product[]
}

export interface IVariant {
  available?: boolean
  compareAtPrice?: string
  formattedPrice?: string
  grams?: number
  id: string | number
  imageVariant?: ImageVariant[]
  optionValues?: OptionValue[]
  price?: {
    amount: string
    currencyCode: string
  }
  priceV2?: {
    amount: string
    currencyCode: string
  }
  image?: {
    src?: string
    height?: number
    width?: number
    altText?: string
  }
  productId?: string | number
  productTitle?: string
  sku?: string
  title?: string | undefined
}

export interface IImage {
  id: string | number
  created_at?: string
  position?: number
  updated_at?: string
  product_id?: string
  src: string
  variant_ids?: Array<string>
}

export interface IProduct {
  id?: string | number
  title?: string | undefined
  images?: IImage[]
  handle?: string
  collections?: Collection[]
  description?: string
  descriptionHtml?: string
  options?: Option[]
  variants: IVariant[]
  selectedVariantImage?: {
    id?: string | number
    created_at?: string
    position?: number
    updated_at?: string
    product_id?: string
    src?: string
    variant_ids?: string[]
  }
  selections?: string[]
  vendor?: string
}

export interface ICart {
  subtotalPrice?: string
  checkoutUrl?: string
  totalPrice?: {
    amount: string
    currencyCode: string
  }
  id?: string | number
  currencyCode?: string
  shippingLine?: number
  discountApplications?: [
    {
      applicable: false
      value: {
        percentage: 0
      }
      code: ''
    },
  ]
  lineItemsSubtotalPrice?: {
    amount: string
    currencyCode: string
  }
  totalPriceV2?: {
    amount: string
    currencyCode: string
  }
  subtotalPriceV2?: {
    amount: string
    currencyCode: string
  }
  totalTaxV2?: {
    amount: string
    currencyCode: string
  }
  lineItems?: ExtendedLineItem[]
}

export default class ProductsStore {
  rootStore: IStores
  checkoutStore: CheckoutStore
  constructor(rootStore: IStores, checkoutStore: CheckoutStore) {
    this.rootStore = rootStore
    this.checkoutStore = checkoutStore
    makeAutoObservable(this)
  }

  public isLoading = false
  public isDiscountCheckLoading = false
  public hasDiscountChecked = false
  public showSearchBar = false
  public noProductsFoundNotice = false
  public productsFilteredNotice = false
  public isProductLoading = {itemId: ''}
  public products!: Product[]
  public productsToDisplay!: Product[]

  public selectedProduct!: Product
  public showProductDetailModal = false
  public selectedVariantId = ''
  public collections!: Collection[]
  public checkoutId = ''
  public cart: ICart = {
    id: '',
    currencyCode: '',
    lineItems: [],
  }
  public discountAdded = false

  @action
  public toggleSearchBar(): void {
    this.showSearchBar = !this.showSearchBar
    this.noProductsFoundNotice = false
  }

  @action
  public setProductDetailModal(depends: boolean): void {
    this.showProductDetailModal = depends
  }

  @action
  public setProducts(products: Product[]) {
    this.isLoading = true

    this.products = products
    this.productsToDisplay = products

    this.isLoading = false
  }

  @action
  public filterProductsBySearchString(searchString: string): void {
    this.isLoading = true

    let productsToDisplay

    if (searchString) {
      const foundProducts = this.products.filter(
        product =>
          (product.variants.edges[0].node.sku &&
            product.variants.edges[0].node.sku
              .toLowerCase()
              .includes(searchString.toLowerCase())) ||
          product.title.toLowerCase().includes(searchString.toLowerCase()),
      )

      if (foundProducts.length !== 0) {
        productsToDisplay = foundProducts
        this.productsFilteredNotice = true
      }
    }

    this.productsToDisplay = productsToDisplay
      ? productsToDisplay
      : this.products

    this.noProductsFoundNotice =
      productsToDisplay || searchString.length === 0 ? false : true

    this.productsFilteredNotice = productsToDisplay ? true : false

    this.isLoading = false
  }

  @action
  public setCollections(collections: any) {
    this.collections = collections
  }

  @action
  public setSelectedProduct(product: Product): void {
    this.selectedProduct = product
  }

  @action
  public async fetchSingleProduct(handle: string): Promise<void> {
    this.rootStore.client.product.fetchByHandle(handle).then((product: any) => {
      this.setSelectedProduct(product)
    })
  }

  @action
  public filterProductsToDisplay(category: string | number): void {
    this.isLoading = true

    const filteredCollection = this.collections.filter(c => c.id === category)

    const productsAfterFilter: Product[] =
      filteredCollection[0].products.edges.map(edge => edge.node)

    if (filteredCollection.length) {
      this.productsToDisplay = productsAfterFilter
    } else {
      this.productsToDisplay = this.products
    }

    this.isLoading = false
  }

  @action
  public async initializeCart(): Promise<void> {
    this.isLoading = true

    const id: string = localStorage.getItem('checkoutId') || this.checkoutId

    let newCart: ICart
    let newCheckoutId: string
    if (id) {
      newCart = await this.rootStore.client.checkout.fetch(id)
      newCheckoutId = newCart.id as string
    } else {
      newCart = await this.rootStore.client.checkout.create()
      newCheckoutId = newCart.id as string
      localStorage.setItem('checkoutId', newCheckoutId)
    }

    this.checkoutId = newCheckoutId
    this.cart = newCart

    this.rootStore.checkoutStore.setCheckoutStep('form')

    this.isLoading = false
  }

  @action
  public async addItemToCart(
    quantity: number,
    variantId: string,
    selectedCollectionHandle?: string,
  ): Promise<void> {
    this.isLoading = true
    this.selectedVariantId = variantId

    const id: string = localStorage.getItem('checkoutId') || this.checkoutId

    let newCart

    if (id) {
      this.checkoutId = id
      newCart = await this.rootStore.client.checkout.addLineItems(
        this.checkoutId,
        [
          {
            variantId: this.selectedVariantId,
            quantity: quantity,
            customAttributes: [
              {
                key: 'collectionHandle',
                value: selectedCollectionHandle,
              },
            ],
          },
        ],
      )
      this.cart = newCart
      this.isLoading = false
    } else {
      await this.initializeCart()
      newCart = await this.rootStore.client.checkout.addLineItems(
        this.checkoutId,
        [
          {
            variantId: this.selectedVariantId,
            quantity: quantity,
          },
        ],
      )
      this.rootStore.checkoutStore.setCheckoutStep('form')

      this.cart = newCart
      this.isLoading = false
    }
  }

  @action
  public async updateQuantityInCart(
    lineItemId: string | number,
    quantity: number,
  ): Promise<void> {
    this.isProductLoading = {itemId: lineItemId as string}
    const lineItemsToUpdate = [{id: lineItemId, quantity}]
    const updatedCart = await this.rootStore.client.checkout.updateLineItems(
      this.checkoutId,
      lineItemsToUpdate,
    )
    this.cart = updatedCart
    this.isProductLoading = {itemId: ''}
  }

  @action
  public async addDiscount(userCodeEntry: string) {
    this.isDiscountCheckLoading = true
    const checkoutId = this.checkoutId // ID of an existing checkout
    const discountCode = userCodeEntry
    this.hasDiscountChecked = true

    // Add a discount code to the checkout
    const checkMaCode = await this.rootStore.client.checkout.addDiscount(
      checkoutId,
      discountCode,
    )
    this.cart = checkMaCode
    this.isDiscountCheckLoading = false
  }

  @action
  public async removeDiscount() {
    this.isDiscountCheckLoading = true

    const checkoutId = this.checkoutId // ID of an existing checkout

    // Removes the applied discount from an existing checkout.
    const checkMaCode = await this.rootStore.client.checkout.removeDiscount(
      checkoutId,
    )
    this.cart = checkMaCode
    this.hasDiscountChecked = false
    this.isDiscountCheckLoading = false
  }

  @action
  public async removeItemInCart(lineItemId: string) {
    this.isProductLoading = {itemId: lineItemId as string}
    const lineItemIdsToRemove = [lineItemId]

    this.rootStore.client.checkout
      .removeLineItems(this.checkoutId, lineItemIdsToRemove)
      .then((checkout: any) => {
        this.cart = checkout
      })

    this.isProductLoading = {itemId: ''}
  }
}
