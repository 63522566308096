import React, {useContext, useEffect} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import {useTranslation} from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

import {StyledCTAButton} from 'styles/componentLibraryElements'

import {
  LoadingContainer,
  SpinnerContainer,
} from 'pages/ProductDetail/ProductDetailPage.styled'

import {CartInnerContent, CartEmpty} from 'components'

const StyledContainer = styled(Container)`
  margin-top: 100px;
`
const StyledRow = styled(Row)`
  margin-bottom: 50px;
`

const StyledHeadline = styled.h2`
  color: #064587;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const CartBox = styled.div`
  box-shadow: 0 13px 36px rgb(0 0 0 / 8%);
  margin-bottom: 5rem;
  margin-top: 3rem;
  padding-bottom: 4rem;
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 24px auto 0 auto;
  width: 400px;

  @media (max-width: 768px) {
    width: auto;
  }
`

const CheckoutButton = styled(StyledCTAButton)`
  padding: 1rem 0;
  max-width: 400px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
    width: auto;
  }
`

const StyledBackButton = styled(Link)`
  width: auto;
  text-decoration: none;
  margin-top: 2rem;
`

const Cart: React.FC = () => {
  const {productsStore} = useContext(StoreContext)
  const {t} = useTranslation()
  const cartIsEmpty = productsStore.cart.lineItems.length === 0

  useEffect(() => {
    productsStore.initializeCart()
    productsStore.setProductDetailModal(false)
  }, [])

  const totalPriceFixed = () => {
    const price = Number(productsStore.cart.totalPriceV2.amount)
    return price.toFixed(2).replace('.', ',')
  }

  if (productsStore.isLoading) {
    return (
      <LoadingContainer>
        <Row className="justify-content-md-center">
          <SpinnerContainer xs lg="2">
            <Spinner animation="border" variant="primary" />
          </SpinnerContainer>
        </Row>
      </LoadingContainer>
    )
  }

  if (cartIsEmpty) {
    return <CartEmpty />
  } else {
    return (
      <StyledContainer>
        <StyledRow className="text-center" xs={1}>
          <StyledHeadline>
            {t('cart.totalSumHeadline')}
            {totalPriceFixed()} {productsStore.cart.totalPriceV2.currencyCode}
          </StyledHeadline>
        </StyledRow>
        <ButtonWrapper>
          <Link to={'/checkout'}>
            <CheckoutButton> {t('cart.checkoutBtn')}</CheckoutButton>
          </Link>
        </ButtonWrapper>
        <Row className="justify-content-center">
          <StyledBackButton to={'/products'}>
            {t('cart.continueShoppingBtn')}
          </StyledBackButton>
        </Row>
        <CartBox>
          <CartInnerContent inSummary={false} />
          <ButtonWrapper>
            <Link to={'/checkout'}>
              <CheckoutButton> {t('cart.checkoutBtn')}</CheckoutButton>
            </Link>
          </ButtonWrapper>
          <Row className="justify-content-center">
            <StyledBackButton to={'/products'}>
              {t('cart.continueShoppingBtn')}
            </StyledBackButton>
          </Row>
        </CartBox>
      </StyledContainer>
    )
  }
}

export default observer(Cart)
