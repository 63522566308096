import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import {CombinedAddressesType} from 'stores/CheckoutStore'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleLeft, faInfoCircle} from '@fortawesome/free-solid-svg-icons'

import {
  ButtonLoadingSpinner,
  ButtonLoadingSpinnerContainer,
  ButtonWrapper,
  CheckoutButton,
  StyledBackButton,
  StyledBillingCheckBox,
  StyledBillingCheckBoxWrapper,
  StyledBillingWrapper,
  StyledContentWrapper,
  StyledCustomCheckbox,
  StyledErrorSpan,
  StyledFormHeadline,
  StyledLeftSide,
  StyledRightSide,
  StyledInvoicesRow,
  StyledInfoIconContainer,
} from './Checkout.styled'

import {
  LoadingContainer,
  SpinnerContainer,
} from 'pages/ProductDetail/ProductDetailPage.styled'

interface IProps {
  initialFormValues?: CombinedAddressesType
}

const CheckoutStepForm: React.FC<IProps> = ({initialFormValues}) => {
  const {productsStore, checkoutStore} = useContext(StoreContext)
  const {t} = useTranslation()
  const [useDifferentDeliveryAddress, setuseDifferentDeliveryAddress] =
    useState(false)

  const FormSchema = yup.object().shape({
    company: yup.string().required(t('checkout.form.errors.company')),
    firstName: yup.string().required(t('checkout.form.errors.firstName')),
    lastName: yup.string().required(t('checkout.form.errors.lastName')),
    email: yup
      .string()
      .email(t('checkout.form.errors.emailFormat'))
      .required(t('checkout.form.errors.email')),
    city: yup.string().required(t('checkout.form.errors.city')),
    address1: yup.string().required(t('checkout.form.errors.address1')),
    zip: yup
      .string()
      .required(t('checkout.form.errors.zip'))
      .matches(/^[0-9]+$/, 'Nur Zahlen')
      .min(5, 'Exakt 5 Zahlen')
      .max(5, 'Exakt 5 Zahlen'),
    differentBillingAddress: yup.boolean(),
    company2: yup.string().when('differentBillingAddress', {
      is: true,
      then: yup.string().required(t('checkout.form.errors.company')),
    }),
    firstName2: yup.string().when('differentBillingAddress', {
      is: true,
      then: yup.string().required(t('checkout.form.errors.firstName')),
    }),
    lastName2: yup.string().when('differentBillingAddress', {
      is: true,
      then: yup.string().required(t('checkout.form.errors.lastName')),
    }),
    email2: yup
      .string()
      .email(t('checkout.form.errors.emailFormat'))
      .when('differentBillingAddress', {
        is: true,
        then: yup.string().email().required(t('checkout.form.errors.email')),
      }),
    city2: yup.string().when('differentBillingAddress', {
      is: true,
      then: yup.string().required(t('checkout.form.errors.city')),
    }),
    address12: yup.string().when('differentBillingAddress', {
      is: true,
      then: yup.string().required(t('checkout.form.errors.address1')),
    }),
    zip2: yup.string().when('differentBillingAddress', {
      is: true,
      then: yup
        .string()
        .required(t('checkout.form.errors.zip'))
        .matches(/^[0-9]+$/, 'Nur Zahlen')
        .min(5, 'Exakt 5 Zahlen')
        .max(5, 'Exakt 5 Zahlen'),
    }),
  })

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<CombinedAddressesType>({
    resolver: yupResolver(FormSchema),
    defaultValues: initialFormValues,
  })

  useEffect(() => {
    productsStore.initializeCart()
    if (initialFormValues.differentBillingAddress) {
      setuseDifferentDeliveryAddress(true)
    }
  }, [])

  const onSubmit = (data: CombinedAddressesType) => {
    checkoutStore.addShippingAddress(data)
  }

  const handleDeliveryAddressCheckbox = () => {
    setuseDifferentDeliveryAddress(!useDifferentDeliveryAddress)
  }

  if (productsStore.isLoading) {
    return (
      <LoadingContainer>
        <Row className="justify-content-md-center">
          <SpinnerContainer xs lg="2">
            <Spinner animation="border" variant="primary" />
          </SpinnerContainer>
        </Row>
      </LoadingContainer>
    )
  }

  return (
    <StyledContentWrapper>
      <StyledLeftSide md={4} lg={3}>
        <h5>{t('checkout.steps.one')}</h5>
        <h4>{t('checkout.stepOneSubtitle')}</h4>
        <p>{t('checkout.enteryourdata')}</p>
      </StyledLeftSide>
      <StyledRightSide lg={{span: 7, offset: 1}}>
        <Row>
          <StyledFormHeadline>
            {t('checkout.summary.tableHeadBillingAddress')}
          </StyledFormHeadline>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row>
              {/* Adding fields here */}
              <Form.Group as={Col} xs="12" controlId="company">
                <FloatingLabel
                  controlId="company"
                  label={t('checkout.form.placeholder.company')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.company')}
                    name="company"
                    isInvalid={!!errors.company}
                    {...register('company')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.company && errors.company.message}
                </StyledErrorSpan>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="firstName">
                <FloatingLabel
                  controlId="firstName"
                  label={t('checkout.form.placeholder.firstName')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.firstName')}
                    name="firstName"
                    isInvalid={!!errors.firstName}
                    {...register('firstName')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.firstName && errors.firstName.message}
                </StyledErrorSpan>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="lastName">
                <FloatingLabel
                  controlId="lastName"
                  label={t('checkout.form.placeholder.lastName')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.lastName')}
                    name="lastName"
                    isInvalid={!!errors.lastName}
                    {...register('lastName')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.lastName && errors.lastName.message}
                </StyledErrorSpan>
              </Form.Group>

              <Form.Group as={Col} xs="12" controlId="email">
                <FloatingLabel
                  controlId="email"
                  label={t('checkout.form.placeholder.email')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.email')}
                    name="email"
                    isInvalid={!!errors.email}
                    {...register('email')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.email && errors.email.message}
                </StyledErrorSpan>
              </Form.Group>

              <Form.Group as={Col} xs="12" controlId="phone">
                <FloatingLabel
                  controlId="phone"
                  label={t('checkout.form.placeholder.phone')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.phone')}
                    name="phone"
                    {...register('phone')}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs="12" controlId="address1">
                <FloatingLabel
                  controlId="address1"
                  label={t('checkout.form.placeholder.address1')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.address1')}
                    name="address1"
                    isInvalid={!!errors.address1}
                    {...register('address1')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.address1 && errors.address1.message}
                </StyledErrorSpan>
              </Form.Group>

              <Form.Group as={Col} xs="12" controlId="zip">
                <FloatingLabel
                  controlId="zip"
                  label={t('checkout.form.placeholder.zip')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.zip')}
                    name="zip"
                    isInvalid={!!errors.zip}
                    {...register('zip')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.zip && errors.zip.message}
                </StyledErrorSpan>
              </Form.Group>

              <Form.Group as={Col} xs="12" controlId="city">
                <FloatingLabel
                  controlId="city"
                  label={t('checkout.form.placeholder.city')}
                  className="mb-3"
                >
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder={t('checkout.form.placeholder.city')}
                    name="city"
                    isInvalid={!!errors.city}
                    {...register('city')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.city && errors.city.message}
                </StyledErrorSpan>
              </Form.Group>

              <StyledBillingCheckBoxWrapper xs="12">
                <Form.Group controlId="differentBillingAddress">
                  <StyledBillingCheckBox>
                    <Form.Check
                      type="checkbox"
                      name="differentBillingAddress"
                      label="Abweichende Lieferadresse verwenden"
                      onClick={handleDeliveryAddressCheckbox}
                      checked={useDifferentDeliveryAddress}
                      {...register('differentBillingAddress')}
                    />
                  </StyledBillingCheckBox>
                </Form.Group>
              </StyledBillingCheckBoxWrapper>

              {useDifferentDeliveryAddress ? (
                <StyledBillingWrapper>
                  <StyledFormHeadline>
                    {t('checkout.summary.tableHeadDeliveryAddress')}
                  </StyledFormHeadline>
                  <Form.Group as={Col} xs="12" controlId="company2">
                    <FloatingLabel
                      controlId="company2"
                      label={t('checkout.form.placeholder.company')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.company')}
                        name="company2"
                        isInvalid={!!errors.company2}
                        {...register('company2')}
                      />
                    </FloatingLabel>
                    <StyledErrorSpan>
                      {errors.company2 && errors.company2.message}
                    </StyledErrorSpan>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="firstName2">
                    <FloatingLabel
                      controlId="firstName2"
                      label={t('checkout.form.placeholder.firstName')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.firstName')}
                        name="firstName2"
                        isInvalid={!!errors.firstName2}
                        {...register('firstName2')}
                      />
                    </FloatingLabel>
                    <StyledErrorSpan>
                      {errors.firstName2 && errors.firstName2.message}
                    </StyledErrorSpan>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="lastName2">
                    <FloatingLabel
                      controlId="lastName2"
                      label={t('checkout.form.placeholder.lastName')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.lastName')}
                        name="lastName2"
                        isInvalid={!!errors.lastName2}
                        {...register('lastName2')}
                      />
                    </FloatingLabel>
                    <StyledErrorSpan>
                      {errors.lastName2 && errors.lastName2.message}
                    </StyledErrorSpan>
                  </Form.Group>

                  <Form.Group as={Col} xs="12" controlId="email2">
                    <FloatingLabel
                      controlId="email2"
                      label={t('checkout.form.placeholder.email')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.email')}
                        name="email2"
                        isInvalid={!!errors.email2}
                        {...register('email2')}
                      />
                    </FloatingLabel>
                    <StyledErrorSpan>
                      {errors.email2 && errors.email2.message}
                    </StyledErrorSpan>
                  </Form.Group>

                  <Form.Group as={Col} xs="12" controlId="phone2">
                    <FloatingLabel
                      controlId="phone2"
                      label={t('checkout.form.placeholder.phone')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.phone')}
                        name="phone2"
                        {...register('phone2')}
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} xs="12" controlId="address12">
                    <FloatingLabel
                      controlId="address12"
                      label={t('checkout.form.placeholder.address1')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.address1')}
                        name="address12"
                        isInvalid={!!errors.address12}
                        {...register('address12')}
                      />
                    </FloatingLabel>
                    <StyledErrorSpan>
                      {errors.address12 && errors.address12.message}
                    </StyledErrorSpan>
                  </Form.Group>

                  <Form.Group as={Col} xs="12" controlId="zip2">
                    <FloatingLabel
                      controlId="zip2"
                      label={t('checkout.form.placeholder.zip')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.zip')}
                        name="zip2"
                        isInvalid={!!errors.zip2}
                        {...register('zip2')}
                      />
                    </FloatingLabel>
                    <StyledErrorSpan>
                      {errors.zip2 && errors.zip2.message}
                    </StyledErrorSpan>
                  </Form.Group>

                  <Form.Group as={Col} xs="12" controlId="city2">
                    <FloatingLabel
                      controlId="city2"
                      label={t('checkout.form.placeholder.city')}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t('checkout.form.placeholder.city')}
                        name="city2"
                        isInvalid={!!errors.city2}
                        {...register('city2')}
                      />
                    </FloatingLabel>
                    <StyledErrorSpan>
                      {errors.city2 && errors.city2.message}
                    </StyledErrorSpan>
                  </Form.Group>
                </StyledBillingWrapper>
              ) : (
                ''
              )}

              <StyledInvoicesRow>
                <StyledFormHeadline>
                  {t('checkout.form.placeholder.invoiceDelivery.title')}
                </StyledFormHeadline>
              </StyledInvoicesRow>

              <Form.Group as={Col} xs="4" controlId="invoices1">
                <Form.Check
                  inline
                  label={t('checkout.form.placeholder.invoiceDelivery.email')}
                  name="invoices1"
                  type="radio"
                  id="invoices1"
                  value="email"
                  {...register('invoices')}
                />
              </Form.Group>

              <Form.Group as={Col} xs="4" controlId="invoices2">
                <Form.Check
                  inline
                  label={t('checkout.form.placeholder.invoiceDelivery.post')}
                  name="invoices2"
                  type="radio"
                  id="invoices2"
                  value="post"
                  {...register('invoices')}
                />
              </Form.Group>

              <StyledInvoicesRow>
                <StyledFormHeadline>
                  {t('checkout.form.placeholder.orderNumber')}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id="info-tooltip">
                        Ihre Bestellnummer wird in der Auftragsbestätigung
                        angezeigt
                      </Tooltip>
                    }
                  >
                    <StyledInfoIconContainer>
                      <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                    </StyledInfoIconContainer>
                  </OverlayTrigger>
                </StyledFormHeadline>
              </StyledInvoicesRow>

              <Form.Group as={Col} xs="12" controlId="orderNumber">
                <FloatingLabel
                  controlId="orderNumber"
                  label={t('checkout.form.placeholder.orderNumber')}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder={t('checkout.form.placeholder.orderNumber')}
                    name="orderNumber"
                    isInvalid={!!errors.orderNumber}
                    {...register('orderNumber')}
                  />
                </FloatingLabel>
                <StyledErrorSpan>
                  {errors.orderNumber && errors.orderNumber.message}
                </StyledErrorSpan>
              </Form.Group>

              <Col xs="12">
                <ButtonWrapper>
                  <CheckoutButton type="submit">
                    {checkoutStore.isLoading ? (
                      <ButtonLoadingSpinnerContainer>
                        {t('checkout.proceedBtn')}
                        <ButtonLoadingSpinner
                          animation="border"
                          variant="white"
                        />
                      </ButtonLoadingSpinnerContainer>
                    ) : (
                      t('checkout.proceedBtn')
                    )}
                  </CheckoutButton>
                </ButtonWrapper>
                <Row>
                  <StyledBackButton to={'/cart'}>
                    <FontAwesomeIcon icon={faAngleLeft} />{' '}
                    {t('checkout.backToCartBtn')}
                  </StyledBackButton>
                </Row>
              </Col>
            </Row>
          </Form>
        </Row>
      </StyledRightSide>
    </StyledContentWrapper>
  )
}

export default observer(CheckoutStepForm)
