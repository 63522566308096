import {createGlobalStyle} from 'styled-components'

import TTNormsProRegular from 'styles/fonts/TTNormsPro-Regular.woff2'
import TTNormsProBold from './TTNormsPro-Bold.woff2'
import TTNormsProMedium from './TTNormsPro-Medium.woff2'

export default createGlobalStyle`
    @font-face {
        font-family: TTNormsPro;
        src: local(''), url(${TTNormsProRegular}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: TTNormsPro;
        src: local(''), url(${TTNormsProBold}) format('woff2');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: TTNormsPro;
        src: local(''), url(${TTNormsProMedium}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }
`
