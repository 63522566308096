import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import {defaultTheme} from 'styles/themes'

export const StyledCTAButton = styled(Button)`
  background-color: ${defaultTheme.primaryColor};
  border-radius: 25px;
  border-style: solid;
  border-color: #fff;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  font-family: ${defaultTheme.fontFamily};
  margin-bottom: 0;
  min-width: 50%;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  transition: 0.3s ease-in-out 0s;

  a {
    text-decoration: none;
    color: inherit;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${defaultTheme.secondaryColor};
    border-color: #fff;
    outline: none;
    box-shadow: none;
  }
`
