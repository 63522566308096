import React from 'react'
import {useTranslation} from 'react-i18next'
import {parseISO, format} from 'date-fns'

import {documentStyles} from './DocumentStyles'
// import {Logo} from './Logo'
import logo2 from '../../assets/images/image7.png'
import waterlogicLogo from '../../assets/images/waterlogicLogo.png'
import {PDFData} from '../../stores/CheckoutStore'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFilePdf} from '@fortawesome/free-solid-svg-icons'

import {
  BlobProvider,
  Document,
  Image,
  Page,
  Text,
  View,
  // PDFDownloadLink,
  // usePDF,
} from '@react-pdf/renderer'

interface IOrderConfirm {
  pdfData?: PDFData
}

interface IDocument {
  data?: PDFData
}

export const OrderConfirmationDocument: React.FC<IOrderConfirm> = (
  props: IOrderConfirm,
) => {
  const {t} = useTranslation()

  const date = format(
    parseISO(props.pdfData.ReceiptDate.PurchaseOrderDate),
    'dd.MM.yyyy',
  )
  if (props.pdfData) {
    return (
      <BlobProvider
        document={<GeneratedDocument data={props.pdfData as PDFData} />}
      >
        {({blob, url, loading, error}) => {
          if (loading) return <div>Lade Inhalte ...</div>
          if (error) return <div>Konnte PDF nicht generieren ...</div>

          return (
            <a
              style={{marginTop: '40px', display: 'block'}}
              href={url}
              download={`AB_WLDE_${props.pdfData.Id}_${date}.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFilePdf} style={{marginRight: '20px'}} />
              {t('checkout.success.downloadMessage')}
            </a>
          )
        }}
      </BlobProvider>
    )
  }
}

// Create Document Component
const GeneratedDocument: React.FC<IDocument> = (props: IDocument) => {
  const deliveryAddress = props.data.Addresses
    ? `${props.data.Addresses[0].Person.Name1} ${props.data.Addresses[0].Person.Name2} ${props.data.Addresses[0].Person.Street} ${props.data.Addresses[0].Person.ZipCode} ${props.data.Addresses[0].Person.Town}`
    : `${props.data.Person.CustomerName1} ${props.data.Person.CustomerStreet} ${props.data.Person.CustomerZipCode} ${props.data.Person.CustomerTown}`

  return (
    <Document>
      <Page size="A4" style={documentStyles.page}>
        <View style={documentStyles.header}>
          <View style={documentStyles.headerLeft}>
            <View style={documentStyles.logoContainerLeft}>
              <Image style={documentStyles.logoLeft} src={logo2}></Image>
            </View>
            <Text style={documentStyles.companyAddress}>
              Waterlogic GmbH | Kleinsachsenheimer Str. 26/1 | D-74321
              Bietigheim-Bissingen
            </Text>
            <Text style={documentStyles.customerAddressLine}>
              {props.data.Person.CustomerName1}
            </Text>
            <Text style={documentStyles.customerAddressLine}>
              {props.data.Person.CustomerStreet}
            </Text>{' '}
            <Text style={documentStyles.customerAddressLine}>
              {props.data.Person.CustomerZipCode}
              {props.data.Person.CustomerTown}
            </Text>{' '}
            <Text style={documentStyles.countryInCustomerAddress}>
              Deutschland
            </Text>
            ={' '}
          </View>
          <View style={documentStyles.headerRight}>
            <View style={documentStyles.logoContainerRight}>
              <Image
                style={documentStyles.waterlogicLogoRight}
                src={waterlogicLogo}
              ></Image>
            </View>
            <View style={documentStyles.abBlock}>
              <Text style={documentStyles.blockHeader}>
                Auftragsbestätigung {props.data.Number}
              </Text>
              <View style={documentStyles.abBlockTextContainer}>
                <Text style={documentStyles.abBlockText}>Datum:</Text>
                <Text style={documentStyles.abBlockText}>
                  {format(
                    parseISO(props.data.ReceiptDate.PurchaseOrderDate),
                    'dd.MM.yyyy',
                  )}
                </Text>
              </View>
              <View style={documentStyles.abBlockTextContainer}>
                <Text style={documentStyles.abBlockText}>Kunde:</Text>
                <Text style={documentStyles.abBlockText}>
                  {props.data.Person.CustomerNumber}
                </Text>
              </View>
              <View style={documentStyles.abBlockTextContainer}>
                <Text style={documentStyles.abBlockText}>bestellt durch:</Text>
                <Text style={documentStyles.abBlockText}>
                  {props.data.Additional.PurchaseOfficer}
                </Text>
              </View>
              <View style={documentStyles.abBlockTextContainer}>
                <Text style={documentStyles.abBlockText}>Tel:</Text>
                <Text style={documentStyles.abBlockText}>
                  {props.data.Communication.CustomerPhone}
                </Text>
              </View>
              <View style={documentStyles.abBlockTextContainer}>
                <Text style={documentStyles.abBlockText}>E-Mail:</Text>
                <Text style={documentStyles.abBlockText}>
                  {props.data.Communication.CustomerEmail}
                </Text>
              </View>

              {props.data.Additional.PurchaseOrderNumber && (
                <View style={documentStyles.abBlockTextContainer}>
                  <Text style={documentStyles.abBlockText}>Ihre Best. Nr:</Text>
                  <Text style={documentStyles.abBlockText}>
                    {props.data.Additional.PurchaseOrderNumber}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
        <View style={documentStyles.main}>
          <View style={documentStyles.titleContainer}>
            <Text style={documentStyles.mainTitle}>Projekt:</Text>
            <Text style={documentStyles.mainTitle2}>
              {props.data.Description}
            </Text>
          </View>
          <View style={documentStyles.deliveryAddressContainer}>
            <Text style={documentStyles.deliveryAddressHeadline}>
              Lieferadresse:
            </Text>
            <Text style={documentStyles.deliveryAddressText}>
              {deliveryAddress}
            </Text>
          </View>
          <View style={documentStyles.positionsContainer}>
            <View style={documentStyles.positionsTableHeader}>
              <Text style={documentStyles.pos}>Pos.</Text>
              <Text style={documentStyles.artnr}>Art.Nr.</Text>
              <Text style={documentStyles.artikelbezeichnung}>
                Artikelbezeichnung
              </Text>
              <Text style={documentStyles.menge}>Menge</Text>
              <Text style={documentStyles.ep}>EP</Text>
              <Text style={documentStyles.pe}>PE</Text>
              <Text style={documentStyles.mwst}>MwSt.</Text>
              <Text style={documentStyles.betrag}>Betrag</Text>
            </View>{' '}
            {props.data.Positions.map(position => {
              return (
                <View
                  key={position.Item.ItemNumber}
                  style={documentStyles.positionsContent}
                >
                  <Text style={documentStyles.pos}>
                    {position.Additional.PositionNumber}
                  </Text>
                  <Text style={documentStyles.artnr}>
                    {position.Item.ItemNumber}
                  </Text>
                  <Text style={documentStyles.artikelbezeichnung}>
                    {position.Item.ItemDescription1}
                  </Text>
                  <Text style={documentStyles.menge}>
                    {position.Quantity.ItemQuantity} Stck.
                  </Text>
                  <Text style={documentStyles.ep}>
                    {position.Price.Price && position.Price.Price.toFixed(2)}
                  </Text>
                  <Text style={documentStyles.pe}>
                    {position.Quantity.PackUnitRelation.ItemsPerPackage}
                  </Text>
                  <Text style={documentStyles.mwst}>19%</Text>
                  <Text style={documentStyles.betrag}>
                    {(
                      position.Price.Price &&
                      position.Price.Price * position.Quantity.ItemQuantity
                    ).toFixed(2)}
                  </Text>
                </View>
              )
            })}
          </View>
          <View style={documentStyles.summaryContainer}>
            <View style={documentStyles.summaryRow}>
              <Text style={documentStyles.totalLine}>Total exkl. MwSt.</Text>{' '}
              <Text style={documentStyles.totalLineCurrency}>EUR</Text>{' '}
              <Text style={documentStyles.totalLineAmount}>
                {props.data.Price.Excl.toFixed(2)}
              </Text>{' '}
            </View>
            <View style={documentStyles.summaryRow}>
              <Text style={documentStyles.totalLine2}>
                19% MwSt. von {props.data.Price.Excl}
              </Text>{' '}
              <Text style={documentStyles.totalLineCurrency2}>EUR</Text>{' '}
              <Text style={documentStyles.totalLineAmount2}>
                {(props.data.Price.Incl - props.data.Price.Excl).toFixed(2)}
              </Text>{' '}
            </View>
          </View>
          <View style={documentStyles.summaryContainer}>
            <View style={documentStyles.summaryRow2}>
              <Text style={documentStyles.totalLine}>Total inkl. MwSt.</Text>{' '}
              <Text style={documentStyles.totalLineCurrency}>EUR</Text>{' '}
              <Text style={documentStyles.totalLineAmount}>
                {props.data.Price.Incl.toFixed(2)}
              </Text>{' '}
            </View>
          </View>
          <View style={documentStyles.summaryContainerFinal}>
            <View style={documentStyles.summaryRow}>
              <Text style={documentStyles.twentyfive}>Lieferart:</Text>{' '}
              <Text style={documentStyles.seventyfive}>
                {props.data.Condition.DeliveryConditionRelation.Display}
              </Text>{' '}
            </View>
            <View style={documentStyles.summaryRow}>
              <Text style={documentStyles.twentyfive}>Lieferbedingungen:</Text>{' '}
              <Text style={documentStyles.seventyfive}>
                {props.data.Condition.ShippingTypeRelation.Display}
              </Text>{' '}
            </View>
            <View style={documentStyles.summaryRow}>
              <Text style={documentStyles.twentyfive}>Zahlungsart:</Text>{' '}
              <Text style={documentStyles.seventyfive}>
                {props.data.Condition.PaymentTypeRelation.Display}
              </Text>{' '}
            </View>
            <View style={documentStyles.summaryRow}>
              <Text style={documentStyles.twentyfive}>
                Zahlungsbedingungen:
              </Text>{' '}
              <Text style={documentStyles.seventyfive}>
                {props.data.Condition.PaymentConditionRelation.Description}
              </Text>{' '}
            </View>
            <View style={documentStyles.summaryRow}>
              <Text style={documentStyles.agbRow}>
                Es gelten unsere allgemeinen Geschäftsbedingungen, in der
                jeweils aktuell gültigen Form einzusehen unter
                www.waterlogic.de/agb
              </Text>
            </View>
          </View>
        </View>
        <View style={documentStyles.footer}>
          <View style={documentStyles.footerRow}>
            <View style={documentStyles.footerColumn}>
              <Text style={documentStyles.bold}>Waterlogic GmbH</Text>
              <Text>Kleinsachsenheimerstr. 26/1</Text>
              <Text>74321 Bietigheim-Bissingen</Text>
            </View>
            <View style={documentStyles.footerColumn}>
              <Text>Tel.: +49.7142.92903.0</Text>
              <Text>Fax.: +49.7142.92903.37</Text>
              <Text>E-Mail: info@waterlogic.de</Text>
            </View>
            <View style={documentStyles.footerColumn}>
              <Text>Geschäftsführer</Text>
              <Text>Olaf Wilhelm</Text>
              <Text>Kamen Dimov</Text>
              <Text>Alen Gaspert</Text>
            </View>
            <View style={documentStyles.footerColumn}>
              <Text>Amtsgericht Stuttgart</Text>
              <Text>HRB 302100</Text>
              <Text>Steuernummer: 55083/11739</Text>
              <Text>USt-IdNr.: DE219565956</Text>
            </View>
            <View style={documentStyles.footerColumn}>
              <Text>Kreissparkasse Ludwidgsburg</Text>
              <Text>IBAN: DE43 6045 0050 0000 0399 16</Text>
              <Text>BIC: SOLA DE S1LBG</Text>
              <Text>WEEE-Reg.-Nr. DE 33443972</Text>
            </View>
            <View style={documentStyles.footerColumn}>
              <Text>Raiffeisen Bank Salzburg</Text>
              <Text>IBAN: AT03 3500 0000 0215 1330</Text>
              <Text>BIC: RVSAAT2S</Text>
            </View>
          </View>
          <View style={documentStyles.footerRow}>
            <View>
              <Text style={documentStyles.lastline}>
                Besuchen Sie uns im Internet:{' '}
                <Text style={documentStyles.bold}>
                  www.waterlogic.de, www.purezza-water.de, www.eauvell.de oder
                  www.facebook.com/waterlogic.gmbh
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
