import styled from 'styled-components'
import {Link} from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'

import Nav from 'react-bootstrap/Nav'

import bgPlaceholderSVG from 'assets/svg/backgroundArc.svg'

export const StyledContainer = styled(Container)`
  margin-top: 100px;
  max-width: 1600px;
`

export const StyledRow = styled(Row)`
  margin-bottom: 50px;
`

export const StyledCard = styled(Card)`
  background-color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  height: calc(100% - 120px);
  margin-bottom: 60px;
  margin-top: 60px;
  padding: 0 25px 25px 25px;
  text-align: center;
  z-index: 1;

  &::before {
    background-image: url(${bgPlaceholderSVG});
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    fill: #f5f5f5;
    height: 28%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleX(-1);
    width: 100%;
    z-index: -1;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
    }
  }
`

export const StyledImageContainer = styled.figure`
  border-radius: 0;
  margin: -40px auto 0 auto;
  padding-top: 66%;
  position: relative;
  width: 66%;
`

export const StyledImageContainerInner = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

export const StyledImage = styled(Card.Img)`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  transition: 0.3s ease-in-out 0s;
`

export const StyledCardH3 = styled.h3`
  font-size: 2.1875rem;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
`

export const StyledCardBody = styled(Card.Body)`
  padding: 1rem 1rem 4rem 1rem;
`

export const StyledNavContainer = styled(Row)`
  cursor: pointer;
  margin-bottom: 80px;

  span {
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.625rem;
    padding: 0 15px;
    text-align: center;
  }
`
export const StyledNav = styled(Nav)`
  align-items: flex-end;
  border-color: #a1a1a4;

  @media (min-width: 1200px) {
    max-width: 65%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    height: 41px;
    flex-wrap: nowrap;
    overflow: auto hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const StyledSearchInputContainer = styled.div`
  height: 41px;

  @media (min-width: 1200px) {
    min-height: 68px;
    max-width: 65%;
    margin: 0 auto;
  }

  input {
    padding: 0.75rem;
    font-size: 1rem;
  }
`

export const StyledSearchResultNotice = styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  height: 5rem;
`

export const StyledNavItem = styled(Nav.Item)`
  position: relative;
  padding-bottom: 15px;

  &:before {
    content: '';
    border-bottom: 10px solid #004b8d;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -10px;
    display: block;
    height: 0;
    left: 50%;
    opacity: ${props => (props.current ? 1 : 0)};
    position: absolute;
    transform: translateX(-50%) rotate(180deg);
    transition: all 0.3s ease-in-out;
    visibility: ${props => (props.current ? 'visible' : 'hidden')};
    width: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:after {
    background: #004b8d;
    bottom: -4px;
    content: '';
    display: block;
    height: 4px;
    left: 0;
    opacity: ${props => (props.current ? 1 : 0)};
    position: absolute;
    transition: all 0.3s ease-in-out;
    visibility: ${props => (props.current ? 'visible' : 'hidden')};
    width: 100%;
    z-index: 1;

    @media (max-width: 768px) {
      bottom: 0;
    }
  }

  &:hover {
    &:after,
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`
export const StyledCTABox = styled.div`
  align-items: center;
  bottom: 20px;
  display: flex;
  justify-content: space-evenly;
  left: 20px;
  position: absolute;
  right: 20px;
`

export const StyledPriceTag = styled.span`
  font-size: 1rem;
  font-weight: 600;
`
