import React, {useEffect, useState, useContext} from 'react'
import {StoreContext} from 'stores/rootStore'
import {useQuery} from '@apollo/client'
import {SINGLE_PRODUCT} from 'utils/api/graphQLQueries'
import {useParams, useNavigate} from 'react-router-dom'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import reactParser from 'html-react-parser'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinus, faPlus, faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {StyledCTAButton} from 'styles/componentLibraryElements'
import Spinner from 'react-bootstrap/Spinner'

import {FixedBasketButton, InfoModal} from 'components'

import {
  CounterTitle,
  StyledImage,
  StyledDecSpan,
  LoadingContainer,
  PageContainer,
  ProductDescription,
  SpinnerContainer,
  StyledContent,
  StyledCounter,
  StyledFigure,
  StyledH1,
  StyledHeroCol,
  StyledIncSpan,
  StyledPageWrapper,
  StyledPrice,
  StyledValue,
  StyledOptions,
  ButtonLoadingSpinner,
  ButtonLoadingSpinnerContainer,
  StyledBackButton,
} from './ProductDetailPage.styled'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ProductDetailPage: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {productsStore} = useContext(StoreContext)
  const params = useParams<string>()
  const [amount, setAmount] = useState(0)
  const [selectedVariantId, setVariantId] = useState<string | number>('')
  const [selectedCollectionHandle, setCollectionHandle] = useState<string>('')
  const {data} = useQuery(SINGLE_PRODUCT, {
    variables: {handle: params.handle},
  })

  const CO2CATEGORY = 'co-2-flaschen-und-zubehor'

  useEffect(() => {
    setAmount(1)
    if (data) {
      const product = data.product
      productsStore.setSelectedProduct(product)
    }
  }, [data])

  useEffect(() => {
    if (productsStore.selectedProduct) {
      setVariantId(productsStore.selectedProduct.variants.edges[0].node.id)
      setCollectionHandle(
        productsStore.selectedProduct.collections.edges[0].node.handle,
      )
    }
  }, [productsStore.selectedProduct])

  useEffect(() => {
    productsStore.initializeCart()
  }, [])

  const handleCounterClick = (counterType: string) => {
    if (counterType === 'increase') {
      setAmount(amount + 1)
    }
    if (counterType === 'decrease') {
      if (amount <= 1) {
        return
      } else {
        setAmount(amount - 1)
      }
    }
  }

  const handleVariantOptionSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const variantId = e.target.value
    setVariantId(variantId)
  }

  const handleAddToCart = async (q: number) => {
    const addToCart = async () => {
      try {
        await productsStore.addItemToCart(
          q,
          selectedVariantId as string,
          selectedCollectionHandle,
        )
        navigate('/cart')
      } catch (error) {
        console.error(error)
      }
    }

    if (productsStore.cart.lineItems.length > 0) {
      const cartContainsCO2 =
        productsStore.cart.lineItems.filter(
          lineItem => lineItem.customAttributes[0].value === CO2CATEGORY,
        ).length > 0

      if (
        (!cartContainsCO2 && selectedCollectionHandle !== CO2CATEGORY) ||
        (cartContainsCO2 && selectedCollectionHandle === CO2CATEGORY)
      ) {
        addToCart()
      } else {
        productsStore.setProductDetailModal(true)
        return
      }
    }

    addToCart()
  }

  if (productsStore.selectedProduct) {
    const {selectedProduct} = productsStore

    const displayImage = selectedProduct.variants.edges[0].node.image
      ? selectedProduct.variants.edges[0].node.image.originalSrc
      : ''

    const dynamicStringHTMLContent = reactParser(
      selectedProduct.descriptionHtml as string,
    )

    const price = Number(selectedProduct.variants.edges[0].node.priceV2.amount)
      .toFixed(2)
      .replace('.', ',')

    const currencyCode =
      selectedProduct.variants.edges[0].node.priceV2.currencyCode

    const productCollectionHandle =
      selectedProduct.collections.edges[0].node.handle

    const isProductInCO2Collection =
      productCollectionHandle === 'co-2-flaschen-und-zubehor'

    const modalTextToDisplay = `Aus abwicklungstechnischen Gründen kann Zubehör nicht gemeinsam mit CO2-Flaschen bestellt werden.
      Bitte geben Sie getrennte Bestellungen dafür auf.`
    const modalTitleToDisplay = `Hinweis zu aktuellem Warenkorb`
    const subcontentToDisplay = `Sie können ihre aktuelle Bestellung hier abschliessen:`
    const modalLinkTo = `/cart`

    return (
      <StyledPageWrapper>
        <InfoModal
          content={modalTextToDisplay}
          subcontent={subcontentToDisplay}
          title={modalTitleToDisplay}
          linkTo={modalLinkTo}
        />
        <FixedBasketButton rightSideFixed />
        <PageContainer>
          <Row>
            <Col md={5}>
              <StyledContent>
                <StyledH1>{selectedProduct.title}</StyledH1>

                <ProductDescription>
                  {dynamicStringHTMLContent}
                </ProductDescription>
                {productsStore.selectedProduct.variants.edges.length > 1 ? (
                  <StyledOptions>
                    <p>{selectedProduct.options[1].name}</p>
                    <select
                      onChange={handleVariantOptionSelect}
                      value={selectedVariantId}
                    >
                      {selectedProduct.variants.edges.map(productVariant => (
                        <option
                          value={productVariant.node.id}
                          key={productVariant.node.title}
                        >
                          {productVariant.node.title}
                        </option>
                      ))}
                    </select>
                  </StyledOptions>
                ) : (
                  ''
                )}

                <StyledCounter>
                  <CounterTitle>{t('general.amount')}</CounterTitle>
                  <StyledDecSpan
                    disabled={amount <= 1}
                    onClick={() => handleCounterClick('decrease')}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </StyledDecSpan>
                  <StyledValue>{amount}</StyledValue>
                  <StyledIncSpan onClick={() => handleCounterClick('increase')}>
                    <FontAwesomeIcon icon={faPlus} />
                  </StyledIncSpan>
                </StyledCounter>
                {!isProductInCO2Collection && (
                  <StyledPrice>
                    {price} {currencyCode}
                  </StyledPrice>
                )}
                <StyledCTAButton onClick={() => handleAddToCart(amount)}>
                  {productsStore.isLoading ? (
                    <ButtonLoadingSpinnerContainer>
                      {t('cart.addToCart')}
                      <ButtonLoadingSpinner
                        animation="border"
                        variant="white"
                      />
                    </ButtonLoadingSpinnerContainer>
                  ) : (
                    t('cart.addToCart')
                  )}
                </StyledCTAButton>
                <StyledBackButton to={'/products'}>
                  <FontAwesomeIcon icon={faAngleLeft} />{' '}
                  {t('general.backToProductsBtn')}
                </StyledBackButton>
              </StyledContent>
            </Col>

            <StyledHeroCol md={{span: 6, offset: 1}}>
              <StyledFigure>
                <StyledImage src={displayImage} />
              </StyledFigure>
            </StyledHeroCol>
          </Row>
        </PageContainer>
      </StyledPageWrapper>
    )
  }

  return (
    <LoadingContainer>
      <Row className="justify-content-md-center">
        <SpinnerContainer xs lg="2">
          <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
      </Row>
    </LoadingContainer>
  )
}

export default observer(ProductDetailPage)
