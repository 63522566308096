import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

import {
  CartControlAmount,
  CartControlElements,
  CartControlRemoveButton,
  DescriptionCol,
  DottedSpinner,
  ImageCol,
  ProductRowLoadingContainer,
  ProductRowWrapper,
  ProductWrowInner,
  StyledCartTableHeader,
  StyledDropdownItem,
  StyledDropdownMenu,
  StyledDropdownToggle,
  StyledPrice,
  StyledProductImage,
  StyledSummaryColHeadline,
  StyledSummaryColRight,
  StyledSummaryColRightDiscount,
  StyledTotalSum,
  StyledVAT,
  SummaryRowInner,
  SummaryRowWrapper,
  TableHeaderWrapper,
  StyledCartLink,
  StyledCheckBtnCol,
  StyledCheckBtn,
} from './CartInnerContent.styled'

import {
  ButtonLoadingSpinnerContainer,
  ButtonLoadingSpinner,
  StyledErrorSpan,
} from '../../pages/Checkout/Checkout.styled'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

interface CartInnerProps {
  inSummary?: boolean
}

export const CartInnerContent: React.FC<CartInnerProps> = observer(props => {
  const {productsStore, checkoutStore, userStore} = useContext(StoreContext)
  const {t} = useTranslation()
  const [discountCode, setDiscountCodeToApply] = useState<string>('')
  const [codeAppliedMessage, setCodeAppliedMessage] = useState<string>('')
  const [discountAdded, setDiscountAdded] = useState<boolean>(false)
  const [discountErrorMessage, setDiscountErrorMessage] = useState<string>('')
  const [infoMessageAfterDiscountAdded, setinfoMessageAfterDiscountAdded] =
    useState<string>('')

  useEffect(() => {
    if (productsStore.cart.discountApplications.length) {
      const savings =
        (productsStore.cart.discountApplications[0].value.percentage / 100) *
        Number(productsStore.cart.lineItemsSubtotalPrice.amount)
      const codeMessage = `Code: ${productsStore.cart.discountApplications[0].code}`
      const infoMessage = `${t('cart.discountMessageAfterSuccess')} ${
        productsStore.cart.discountApplications[0].value.percentage
      }% or ${savings.toFixed(2)} EUR`
      setDiscountAdded(true)
      setinfoMessageAfterDiscountAdded(infoMessage)
      setCodeAppliedMessage(codeMessage)
    }

    if (productsStore.hasDiscountChecked) {
      setDiscountErrorMessage(`Der eingegebene Code ist nicht gueltig`)
    }
  }, [productsStore.cart])

  const handleItemCount = (id: string | number, amount: number) => {
    productsStore.updateQuantityInCart(id, amount)
  }

  const handleItemRemove = (id: string) => {
    productsStore.removeItemInCart(id)
  }

  const handleDiscountCodeSet = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setDiscountCodeToApply(e.target.value)
  }

  const handleDiscountCodeCheck = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setDiscountErrorMessage('')
    productsStore.addDiscount(discountCode)
  }

  const handleDiscountRemoval = () => {
    productsStore.removeDiscount()
    setCodeAppliedMessage('')
    setDiscountErrorMessage('')
    setDiscountCodeToApply('')
    setDiscountAdded(false)
  }

  const CO2CATEGORY = 'co-2-flaschen-und-zubehor'
  const cartContainsCO2 =
    productsStore.cart.lineItems.filter(
      lineItem => lineItem.customAttributes[0].value === CO2CATEGORY,
    ).length > 0

  if (cartContainsCO2) {
    checkoutStore.setContainsCO2Products(true)
  }

  const subTotalPriceFixed = () => {
    const price = Number(productsStore.cart.subtotalPriceV2.amount)
    return price.toFixed(2).replace('.', ',')
  }

  const totalPrice = () => {
    const netPrice = Number(productsStore.cart.totalPrice.amount)
    const includingTaxAndShipping = netPrice + calculatedShippingCosts()

    return cartContainsCO2
      ? netPrice
      : includingTaxAndShipping.toFixed(2).replace('.', ',')
  }

  const calculatedShippingCosts = (): number => {
    let shippingCost: number
    const totalCartNetSum = Number(productsStore.cart.totalPrice.amount)

    if (totalCartNetSum <= 49.99) {
      shippingCost = 6.9
    }

    if (totalCartNetSum >= 50 && totalCartNetSum <= 199) {
      shippingCost = 9.9
    }

    if (totalCartNetSum >= 200) {
      shippingCost = 12.9
    }

    return shippingCost
  }

  const handleCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemVariantSku: string | number,
    type: string,
  ) => {
    let amount
    if (e.target.checked) {
      amount = 1
    } else {
      amount = ''
    }
    checkoutStore.setCO2Extras(itemVariantSku, e.target.checked, type, amount)
  }

  const handleAmountInputChange = (
    e: React.ChangeEvent<any>,
    itemVariantSku: string | number,
    type: string,
    itemQuantity: number,
  ) => {
    let amountVal = Number(e.target.value)
    if (amountVal > itemQuantity) {
      amountVal = itemQuantity
    }
    if (amountVal < 1) {
      amountVal = 1
    }
    checkoutStore.setCO2Extras(itemVariantSku, true, type, amountVal)
  }

  const handleAmountOnFocus = (
    itemVariantSku: string | number,
    type: string,
  ): void => {
    checkoutStore.setCO2Extras(itemVariantSku, true, type, '')
  }

  const handleAmountOnBlur = (
    itemVariantSku: string | number,
    type: string,
    itemQuantity: number,
  ): void => {
    if (itemQuantity < 1) {
      checkoutStore.setCO2Extras(itemVariantSku, true, type, 1)
    }
  }

  return (
    <>
      <TableHeaderWrapper>
        <StyledCartTableHeader
          sm={{span: 6, offset: 8}}
          md={{span: 4, offset: 8}}
        ></StyledCartTableHeader>
      </TableHeaderWrapper>

      {productsStore.cart.lineItems?.map(item => {
        const subTotalPerItem = () => {
          const price =
            Number(item.variant?.price.amount) * Number(item.quantity)
          return price.toFixed(2).replace('.', ',')
        }

        return (
          <ProductRowWrapper key={item.id}>
            <ProductWrowInner inSummary={props.inSummary}>
              {productsStore.isProductLoading.itemId === item.id ? (
                <ProductRowLoadingContainer>
                  <DottedSpinner animation="grow" variant="primary" />
                  <DottedSpinner animation="grow" variant="primary" />
                  <DottedSpinner animation="grow" variant="primary" />
                </ProductRowLoadingContainer>
              ) : (
                ''
              )}
              <Row>
                <ImageCol md={3}>
                  {item.variant?.image?.src && (
                    <Link to={`/products/${item.variant.product.handle}`}>
                      <StyledProductImage
                        src={item.variant?.image.src}
                      ></StyledProductImage>
                    </Link>
                  )}
                </ImageCol>
                <DescriptionCol md={5}>
                  <h3>
                    <StyledCartLink
                      to={`/products/${item.variant.product.handle}`}
                    >
                      {item.title}
                    </StyledCartLink>
                    <p>
                      {t('cart.articleNumber')} {item.variant.sku}
                    </p>
                    {item.variant.title !== 'Default Title' ? (
                      <p>VPE-Inhalt: {item.variant.title}</p>
                    ) : (
                      ''
                    )}

                    {cartContainsCO2 && (
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: '16px',
                        }}
                      >
                        <br></br>
                        <Form>
                          <Form.Check
                            type="switch"
                            id={`anschluss-switch ${item.variant.sku}`}
                            label="Wünschen Sie den Anschluss der CO2-Flasche (n)?"
                            checked={
                              checkoutStore.co2extras[item.variant.sku] &&
                              checkoutStore.co2extras[item.variant.sku][
                                'anschluss-switch'
                              ]
                            }
                            onChange={e =>
                              handleCheckboxOnChange(
                                e,
                                item.variant.sku,
                                'anschluss-switch',
                              )
                            }
                          />
                          {checkoutStore.co2extras[item.variant.sku] &&
                            checkoutStore.co2extras[item.variant.sku][
                              'anschluss-switch'
                            ] && (
                              <Form.Group
                                as={Col}
                                xs="12"
                                controlId="amountAnschluss"
                                className="mt-3"
                              >
                                <FloatingLabel
                                  controlId="amountAnschluss"
                                  label={t(
                                    'checkout.form.placeholder.amountAnschluss',
                                  )}
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    onChange={e =>
                                      handleAmountInputChange(
                                        e,
                                        item.variant.sku,
                                        'anschluss-switch',
                                        item.quantity,
                                      )
                                    }
                                    onFocus={() =>
                                      handleAmountOnFocus(
                                        item.variant.sku,
                                        'anschluss-switch',
                                      )
                                    }
                                    onBlur={() =>
                                      handleAmountOnBlur(
                                        item.variant.sku,
                                        'anschluss-switch',
                                        checkoutStore.co2extras[
                                          item.variant.sku
                                        ]['anschluss-switch-amount'],
                                      )
                                    }
                                    placeholder={t(
                                      'checkout.form.placeholder.amountAnschluss',
                                    )}
                                    name="amountAnschluss"
                                    max={item.quantity}
                                    value={
                                      checkoutStore.co2extras[item.variant.sku][
                                        'anschluss-switch-amount'
                                      ]
                                    }
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            )}
                          <br></br>
                          {userStore.user.alreadyOrderedCO2 && (
                            <Form.Check
                              type="switch"
                              id={`ruecknahme-switch ${item.variant.sku}`}
                              label="Wünschen Sie die Rücknahme leerer CO2-Flaschen?"
                              checked={
                                checkoutStore.co2extras[item.variant.sku] &&
                                checkoutStore.co2extras[item.variant.sku][
                                  'ruecknahme-switch'
                                ]
                              }
                              onChange={e =>
                                handleCheckboxOnChange(
                                  e,
                                  item.variant.sku,
                                  'ruecknahme-switch',
                                )
                              }
                            />
                          )}

                          {checkoutStore.co2extras[item.variant.sku] &&
                            checkoutStore.co2extras[item.variant.sku][
                              'ruecknahme-switch'
                            ] &&
                            userStore.user.alreadyOrderedCO2 && (
                              <Form.Group
                                as={Col}
                                xs="12"
                                controlId="amountRuecknahme"
                                className="mt-3"
                              >
                                <FloatingLabel
                                  controlId="amountRuecknahme"
                                  label={t(
                                    'checkout.form.placeholder.amountRuecknahme',
                                  )}
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    onChange={e =>
                                      handleAmountInputChange(
                                        e,
                                        item.variant.sku,
                                        'ruecknahme-switch',
                                        item.quantity,
                                      )
                                    }
                                    onFocus={() =>
                                      handleAmountOnFocus(
                                        item.variant.sku,
                                        'ruecknahme-switch',
                                      )
                                    }
                                    onBlur={() =>
                                      handleAmountOnBlur(
                                        item.variant.sku,
                                        'ruecknahme-switch',
                                        checkoutStore.co2extras[
                                          item.variant.sku
                                        ]['ruecknahme-switch-amount'],
                                      )
                                    }
                                    placeholder={t(
                                      'checkout.form.placeholder.amountRuecknahme',
                                    )}
                                    name="amountRuecknahme"
                                    max={item.quantity}
                                    value={
                                      checkoutStore.co2extras[item.variant.sku][
                                        'ruecknahme-switch-amount'
                                      ]
                                    }
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            )}
                        </Form>
                        <br></br>
                      </div>
                    )}
                  </h3>

                  <CartControlElements>
                    <CartControlAmount>
                      <Dropdown className="d-inline">
                        <StyledDropdownToggle id="dropdown-autoclose-true">
                          {item.quantity}
                        </StyledDropdownToggle>

                        <StyledDropdownMenu>
                          {Array.from({length: 15}).map((_, idx) => (
                            <StyledDropdownItem
                              active={item.quantity === idx + 1}
                              key={`unique-${idx}`}
                              onClick={() => handleItemCount(item.id, idx)}
                            >
                              {++idx}
                            </StyledDropdownItem>
                          ))}
                        </StyledDropdownMenu>
                      </Dropdown>
                    </CartControlAmount>
                    <CartControlRemoveButton
                      onClick={() => handleItemRemove(item.id as string)}
                    >
                      {t('cart.deleteItem')}
                    </CartControlRemoveButton>
                  </CartControlElements>
                </DescriptionCol>
                <Col md={4} sm={12}>
                  <StyledPrice>
                    {subTotalPerItem()} {item.variant?.priceV2.currencyCode}
                  </StyledPrice>
                </Col>
              </Row>
            </ProductWrowInner>
          </ProductRowWrapper>
        )
      })}
      {/* Summary Area  */}
      {!productsStore.isLoading && (
        <>
          <SummaryRowWrapper>
            <SummaryRowInner inSummary={props.inSummary}>
              <Row>
                <StyledSummaryColHeadline sm={6} md={8}>
                  <span>{t('cart.subtotal')}</span>
                </StyledSummaryColHeadline>
                <StyledSummaryColRight sm={6} md={4}>
                  <Row>
                    <Col sm={12}>
                      <span>
                        {subTotalPriceFixed()}{' '}
                        {productsStore.cart.subtotalPriceV2.currencyCode}
                      </span>
                    </Col>
                  </Row>
                </StyledSummaryColRight>
                {codeAppliedMessage.length ? (
                  <Col sm={12}>
                    <Alert key={'info'} variant={'info'}>
                      {infoMessageAfterDiscountAdded}
                    </Alert>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </SummaryRowInner>
          </SummaryRowWrapper>
          <SummaryRowWrapper>
            <SummaryRowInner inSummary={props.inSummary}>
              <Row>
                <StyledSummaryColHeadline sm={6} md={8}>
                  <span>{t('cart.shipping')}</span>
                </StyledSummaryColHeadline>
                <StyledSummaryColRight sm={6} md={4}>
                  <Row>
                    <Col sm={12}>
                      {cartContainsCO2 ? (
                        <span>{t('cart.shippingFree')}</span>
                      ) : (
                        <span>
                          {calculatedShippingCosts()
                            .toFixed(2)
                            .replace('.', ',')}{' '}
                          {productsStore.cart.totalPriceV2.currencyCode}
                        </span>
                      )}
                    </Col>
                  </Row>
                </StyledSummaryColRight>
              </Row>
            </SummaryRowInner>
          </SummaryRowWrapper>

          {/* This Summary Row is for Discount */}
          <SummaryRowWrapper>
            <SummaryRowInner inSummary={props.inSummary}>
              <Row>
                <StyledSummaryColHeadline sm={12} lg={6}>
                  <span>{t('cart.discount')}</span>
                </StyledSummaryColHeadline>
                <StyledSummaryColRightDiscount
                  discountadded={discountAdded && true}
                  sm={12}
                  lg={6}
                >
                  {codeAppliedMessage.length ? (
                    <>
                      <div>{codeAppliedMessage}</div>
                      <CartControlRemoveButton onClick={handleDiscountRemoval}>
                        {t('cart.removeDiscountBtn')}
                      </CartControlRemoveButton>
                    </>
                  ) : (
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Col}
                          xs="12"
                          controlId="discountCode"
                          className="mt-3"
                        >
                          <FloatingLabel
                            controlId="discountCode"
                            label={t('checkout.form.placeholder.discountCode')}
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              onChange={handleDiscountCodeSet}
                              placeholder={t(
                                'checkout.form.placeholder.discountCode',
                              )}
                              name="discountCode"
                              value={discountCode}
                            />
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <StyledCheckBtnCol sm={4}>
                        {' '}
                        <StyledCheckBtn
                          className="btn-lg"
                          variant="primary"
                          type="submit"
                          onClick={handleDiscountCodeCheck}
                          loading={productsStore.isDiscountCheckLoading && true}
                        >
                          {productsStore.isDiscountCheckLoading ? (
                            <ButtonLoadingSpinnerContainer>
                              <ButtonLoadingSpinner
                                animation="border"
                                variant="white"
                              />
                            </ButtonLoadingSpinnerContainer>
                          ) : (
                            t('cart.addDiscountBtn')
                          )}
                        </StyledCheckBtn>
                      </StyledCheckBtnCol>
                      {discountErrorMessage && (
                        <StyledErrorSpan>
                          {discountErrorMessage}
                        </StyledErrorSpan>
                      )}
                    </Row>
                  )}
                </StyledSummaryColRightDiscount>
              </Row>
            </SummaryRowInner>
          </SummaryRowWrapper>

          {/* END END END END DISCOUNT */}

          <SummaryRowWrapper>
            <SummaryRowInner inSummary={props.inSummary}>
              <Row>
                <StyledSummaryColHeadline sm={6} md={8}>
                  <span>{t('cart.total')}</span>
                  <StyledVAT>{t('cart.exclTax')}</StyledVAT>
                </StyledSummaryColHeadline>
                <StyledSummaryColRight sm={6} md={4}>
                  <Row>
                    <Col sm={{span: 10, offset: 2}}>
                      <StyledTotalSum>
                        {`
                        ${totalPrice()}
                        ${productsStore.cart.totalPriceV2.currencyCode}
                      `}
                      </StyledTotalSum>
                    </Col>
                  </Row>
                </StyledSummaryColRight>
              </Row>
            </SummaryRowInner>
          </SummaryRowWrapper>
        </>
      )}
    </>
  )
})
