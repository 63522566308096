import React, {useContext} from 'react'
import {observer} from 'mobx-react'
import {StoreContext} from 'stores/rootStore'
import {useTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

import {
  SuccessMessageContainer,
  StyledIconContainer,
  StyledSuccessMessage,
  SuccessLink,
} from './Checkout.styled'

import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import {
  LoadingContainer,
  SpinnerContainer,
} from 'pages/ProductDetail/ProductDetailPage.styled'

import {OrderConfirmationDocument} from 'components'

const CheckoutStepSuccess: React.FC = () => {
  const {checkoutStore} = useContext(StoreContext)
  const {t} = useTranslation()

  if (checkoutStore.orderSent) {
    return (
      <SuccessMessageContainer>
        <StyledIconContainer>
          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
        </StyledIconContainer>
        <StyledSuccessMessage>
          {t('checkout.success.message')}
        </StyledSuccessMessage>

        {checkoutStore.pdfData && (
          <StyledSuccessMessage>
            <OrderConfirmationDocument pdfData={checkoutStore.pdfData} />
          </StyledSuccessMessage>
        )}
        <SuccessLink to="/">{t('checkout.success.linkText')}</SuccessLink>
      </SuccessMessageContainer>
    )
  }
  return (
    <LoadingContainer>
      <Row className="justify-content-md-center">
        <SpinnerContainer xs lg="2">
          Loading...{'  '}
          <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
      </Row>
    </LoadingContainer>
  )
}

export default observer(CheckoutStepSuccess)
