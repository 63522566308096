import React from 'react'
import ReactDOM from 'react-dom'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import {rootStore, StoreContext} from 'stores/rootStore'
import {setContext} from '@apollo/client/link/context'

import {BrowserRouter} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import {defaultTheme, WLDETheme} from './styles/themes'
import GlobalFonts from 'styles/fonts/fonts'
import GlobalStyles from 'styles/global'
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals'
import {Routing as App} from './routes'
import {config} from 'constants/sfconfig'

import './i18n'

const httpLink = createHttpLink({
  uri: 'https://waterlogic-de.myshopify.com/api/2021-10/graphql.json',
})

const middlewareLink = setContext(() => ({
  headers: {
    'X-Shopify-Storefront-Access-Token': '93a39e3e071c529bc8c8dc15c9cb5a5f',
  },
}))

const graphQLClient = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache(),
})

// Get Authentication with CallBack URL and ClientID in case user is not logged in
if (!rootStore.userStore.user.firstName) {
  const callbackUrl = config.sfcall.CALLBACK_URL
  const clientId = config.sfcall.CLIENT_ID
  const authUrl = `https://waterlogic.my.site.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callbackUrl}&display=popup`

  const params = new URLSearchParams(window.location.search)
  const codeParam = params.get('code')

  if (!codeParam) {
    window.location.replace(authUrl)
  }

  if (codeParam) {
    rootStore.userStore.triggerGetUser(codeParam)
  }
}

const element = (
  <React.StrictMode>
    <StoreContext.Provider value={rootStore}>
      <ThemeProvider theme={defaultTheme as WLDETheme}>
        <GlobalFonts />
        <GlobalStyles />
        <BrowserRouter>
          <ApolloProvider client={graphQLClient}>
            <App />
          </ApolloProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StoreContext.Provider>
  </React.StrictMode>
)

const container = document.getElementById('root')

ReactDOM.render(element, container)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
