import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
    body, html {
     color: #404145;
     font-family: TTNormsPro;
    }

    p {
     font-size: 1rem;
     line-height: 1.625rem;
    }
`
